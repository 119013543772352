import { DateAsText, FragmentGrid } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Textarea } from '@/components/Form/Textarea'
import { formatPhone } from '@/utils'

export const InformationSection = ({ dataExam, onOpen }) => {
  return (
    <>
      <h1 className='font-bold text-3xl mb-3'>Exame #{dataExam.number}</h1>
      <div className='flex flex-col'>
        <h3 className='font-bold text-xl mb-3'>Paciente</h3>
        <div className='grid grid-cols-1 gap-2 md:gap-0 md:grid-cols-3 text-lg'>
          <FragmentGrid title='Nome' body={dataExam.patientName} />
          <FragmentGrid title='Data de Nascimento' body={<DateAsText date={dataExam.patientBirthDate} />} />
          <FragmentGrid title='Procedimento' body={dataExam.type} />
        </div>
        <h3 className='font-bold text-xl mb-3'>Laudador</h3>
        <div className='grid grid-cols-1 gap-2 md:gap-0 md:grid-cols-3 text-lg'>
          <FragmentGrid title='Nome' body={dataExam.eulogist.name} />
          <FragmentGrid title='Telefone' body={formatPhone(dataExam.eulogist.phone)} />
          <FragmentGrid title='E-mail' body={dataExam.eulogist.email} />
        </div>
        <h3 className='font-bold text-xl mb-3'>Informações</h3>
        <div className='grid grid-cols-1 gap-2 md:gap-0 md:grid-cols-5 text-lg'>
          <FragmentGrid title='Data do Laudo' body={<DateAsText date={dataExam.date} />} />
          <FragmentGrid title='Unidade' body={dataExam.unity} />
          <FragmentGrid title='Valor Custo' body={<MoneyAsText value={dataExam.costValue} />} />
          <FragmentGrid title='Dr(a) Solicitante' body={`Dr(a) ${dataExam.requesterName}`} className='col-span-2' />
        </div>
        <h3 className='font-bold text-xl mb-3'>Observações</h3>
        <div className='grid grid-cols-1 text-lg'>
          <Textarea value={dataExam.observations} />
        </div>
        <span onClick={() => onOpen()} className='col-span-3 text-center mt-3 underline hover:opacity-70 cursor-pointer'>
          Clique aqui para visualizar os documentos do Exame
        </span>
      </div>
    </>
  )
}
