// Status Exam
export const GENERATED = 1
export const SEND_TO_EULOGIST = 2
export const READ_BY_EULOGIST = 3
export const RATED = 4
export const APPROVED = 5
export const REFUSED = 6

// Units
export const CAMPOS = 1
export const SAO_FIDELIS = 2

// Transactions Type
export const RECEIPT = 1
export const EXPENSE = 2

// Transactions Status

export const PENDING = 1
export const PAID = 2

export const getStatusExam = status => {
  switch (status) {
    case GENERATED:
      return 'Gerado'
    case SEND_TO_EULOGIST:
      return 'Enviado'
    case READ_BY_EULOGIST:
      return 'Lido'
    case RATED:
      return 'Avaliado'
    case APPROVED:
      return 'Finalizado'
    case REFUSED:
      return 'Recusado'
    default:
      throw new Error(`Invalid Transaction Status "${status}".`)
  }
}

export const getUnits = status => {
  switch (status) {
    case CAMPOS:
      return 'Campos'
    case SAO_FIDELIS:
      return 'São Fidelis'
    default:
      throw new Error(`Invalid Transaction Status "${status}".`)
  }
}

export const getTransactionType = type => {
  switch (type) {
    case RECEIPT:
      return 'Receita'
    case EXPENSE:
      return 'Despesa'
    default:
      throw new Error(`Invalid Transaction Status "${type}".`)
  }
}

export const getTransactionStatus = status => {
  switch (status) {
    case PENDING:
      return 'Pendente'
    case PAID:
      return 'Pago'
    default:
      throw new Error(`Invalid Transaction Status "${status}".`)
  }
}

export const optionsStatusExam = () => {
  return [
    { value: 1, label: getStatusExam(1) },
    { value: 2, label: getStatusExam(2) },
    { value: 3, label: getStatusExam(3) },
    { value: 4, label: getStatusExam(4) },
    { value: 5, label: getStatusExam(5) },
    { value: 6, label: getStatusExam(6) },
  ]
}

export const optionsUnits = () => {
  return [
    { value: getUnits(1), label: getUnits(1) },
    { value: getUnits(2), label: getUnits(2) },
  ]
}

export const optionsTransactionsType = () => {
  return [
    { value: getTransactionType(1), label: getTransactionType(1) },
    { value: getTransactionType(2), label: getTransactionType(2) },
  ]
}

export const optionsTransactionsStatus = () => {
  return [
    { value: PENDING, label: getTransactionStatus(1) },
    { value: PAID, label: getTransactionStatus(2) },
  ]
}
