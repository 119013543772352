import * as Yup from 'yup'
import { useFormik } from 'formik'

export const useFormSearchEulogist = ({ onSubmit }) => {
  const Schema = Yup.object({
    eulogistName: Yup.string(),
  })

  return useFormik({
    initialValues: {
      eulogistName: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
