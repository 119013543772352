import ReferenceLink from '@/components/Common/ReferenceLink'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
} from '@chakra-ui/react'

export const ModalCopyLink = ({ indicationUrl, pass, isOpen, onClose }) => {
  const onCloseModal = () => {
    onClose()
  }
  return (
    <Modal isCentered isOpen={isOpen} size='xl' scrollBehavior='inside' onClose={onCloseModal}>
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='10px' />
      <ModalContent bg='surface'>
        <ModalHeader className='flex justify-between'>
          <Heading as='h2' size='md'>
            Link para Avaliação do Laudador
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className='flex flex-col items-center justify-center'>
            <ReferenceLink indicationUrl={indicationUrl} />
            <div className='my-2 text-center'>
              <p className='text-xl font-bold'>Código para avaliação</p>
              <p className='text-xl font-bold'>{pass}</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseModal}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
