import React from 'react'
import { Button } from '@/components/Form'
import { FieldMessage, PasswordField, TextField } from '@/components/Form/Fields'
import ReCAPTCHA from 'react-google-recaptcha'

export const SignInForm = ({ loading, handleSubmit, handleChange, values = {}, errors = {}, setFieldValue }) => {
  return (
    <>
      <form className='w-full flex flex-col gap-2 px-3 my-4 md:max-w-md' onSubmit={handleSubmit}>
        <TextField
          label='E-mail'
          name='email'
          placeholder='Ex.: joao@email.com'
          value={values.email}
          errorMessage={errors.email}
          onChange={handleChange}
          isDisabled={loading}
        />
        <PasswordField
          label='Senha'
          name='password'
          placeholder='******'
          value={values.password}
          errorMessage={errors.password}
          onChange={handleChange}
          isDisabled={loading}
        />
        <div className='flex flex-col items-center'>
          <ReCAPTCHA
            name='recaptcha'
            render='explicit'
            onChange={value => setFieldValue('recaptcha', value)}
            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
          />
          {errors.recaptcha && <FieldMessage message={errors.recaptcha} />}
        </div>
        <div className='grid place-content-center'>
          <Button text='Entrar' type='submit' isDisabled={loading} />
        </div>
      </form>
      <iframe frameBorder='0' scrolling='no' src='https://astrotech.solutions/assinatura/light.html' width='100%' />
    </>
  )
}
