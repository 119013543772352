import { Link, useNavigate } from 'react-router-dom'
import { Logo } from '../Common'
import classNames from 'classnames'
import { useAuth } from '@/contexts'
import { NavItem } from './NavItem'
import { Menu } from '@chakra-ui/react'
import { FaStethoscope, BiMoney, IoLogOutOutline, HiOutlineDocumentText, RiLockPasswordLine } from 'react-icons/all'

export const Header = ({ handleOpenModalChangePass }) => {
  const navigate = useNavigate()
  const { setToken } = useAuth()

  return (
    <nav className='flex items-center justify-between flex-wrap bg-surface mb-4 px-5'>
      <Link className='flex items-center shrink-0 text-white mr-6' to='/'>
        <Logo width={100} />
      </Link>

      <div className='flex gap-4 items-center justify-center text-gray-700 font-bold text-md '>
        <Menu>
          <span className='flex justify-center items-center'>
            <FaStethoscope size={22} />
            <NavItem className='w-full' path='/eulogist' text='Laudadores' />
          </span>
          <span className='flex justify-center items-center'>
            <HiOutlineDocumentText size={22} />
            <NavItem className='w-full' path='/exam' text='Exames' />
          </span>
          <span className='flex justify-center items-center'>
            <BiMoney size={22} />
            <NavItem className='w-full' path='/financial' text='Financeiro' />
          </span>

          <span onClick={handleOpenModalChangePass} className='flex justify-center items-center font-semibold cursor-pointer'>
            <RiLockPasswordLine size={22} />
            <p className='p-2 hover:text-gray-700/70'>Alterar Senha</p>
          </span>
        </Menu>
        <a
          onClick={() => {
            setToken(null)
            navigate('/sign-in', { replace: true })
          }}
          className={classNames('cursor-pointer pr-2 hover:text-gray-700/70 flex gap-2 justify-center items-center')}
        >
          <IoLogOutOutline size={22} />
          Sair
        </a>
      </div>
    </nav>
  )
}
