const endpoints = [
  {
    name: 'sign-in',
    route: '/sign-in/admin',
    method: 'post',
  },
  {
    name: 'me',
    route: '/me',
    method: 'get',
  },
  {
    name: 'fetchExams',
    route: '/exams',
    method: 'get',
  },
  {
    name: 'fetchExam',
    route: '/exams/{id}',
    method: 'get',
  },
  {
    name: 'uploadExamArchive',
    //route: '/exams/upload-archive',
    route: import.meta.env.VITE_EXAM_UPLOAD_ENDPOINT,
    method: 'post',
  },
  {
    name: 'fetchEulogists',
    route: '/eulogists',
    method: 'get',
  },
  {
    name: 'fetchEulogist',
    route: '/eulogists/{id}',
    method: 'get',
  },
  {
    name: 'editEulogist',
    route: '/eulogists/{id}',
    method: 'post',
  },
  {
    name: 'addEulogist',
    route: '/eulogists',
    method: 'post',
  },
  {
    name: 'deleteEulogist',
    route: '/eulogists/{id}',
    method: 'delete',
  },
  {
    name: 'fetchUnits',
    route: '/units',
    method: 'get',
  },
  {
    name: 'editExam',
    route: '/exams/{id}',
    method: 'post',
  },
  {
    name: 'addExam',
    route: '/exams',
    method: 'post',
  },
  {
    name: 'sendAssessment',
    route: import.meta.env.VITE_ASSESSTMENT_UPLOAD_ENDPOINT,
    method: 'post',
  },
  {
    name: 'getDetailsAssessment',
    route: '/exams/{id}',
    method: 'get',
  },
  {
    name: 'deleteExam',
    route: '/exams/{id}',
    method: 'delete',
  },
  {
    name: 'getDetailsAssessmentAvaliate',
    route: '/exams/{id}/get-for-avaliate',
    method: 'get',
  },
  {
    name: 'fetchEulogistsOptions',
    route: '/eulogists/options',
    method: 'get',
  },
  {
    name: 'sendEmail',
    route: '/exams/{id}/send-to-eulogist',
    method: 'post',
  },
  {
    name: 'fetchTransactions',
    route: '/transactions',
    method: 'get',
  },
  {
    name: 'fetchAssesssmentExam',
    route: '/exams/{id}/get-for-avaliate',
    method: 'get',
  },
  {
    name: 'transactionsPayOff',
    route: '/transactions/pay-off',
    method: 'post',
  },
  {
    name: 'approveExam',
    route: '/exams/{id}/approve',
    method: 'post',
  },
  {
    name: 'reproveExam',
    route: '/exams/{id}/refuse',
    method: 'post',
  },
  {
    name: 'changePassword',
    route: '/admin/change-password',
    method: 'put',
  },
  {
    name: 'deleteDocument',
    route: '/documents',
    method: 'post',
  },
  {
    name: 'fetchDocument',
    route: '/exams',
    method: 'get',
  },

]

export const getEndpoint = (routeName, routeParams = {}) => {
  const endpointsCopy = JSON.parse(JSON.stringify(endpoints))
  const endpoint = endpointsCopy.find(row => row.name === routeName)
  if (!endpoint) throw new Error(`Invalid endpoint name '${routeName}'.`)
  for (let paramName in routeParams) {
    endpoint.route = endpoint.route.replace(`{${paramName}}`, routeParams[paramName])
  }
  return endpoint
}
