import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '@/contexts/AuthContext'
import { AppRoutes } from './components/Common'
import 'react-toastify/dist/ReactToastify.css'
import './i18n'
import './styles.scss'
import { theme } from '@/pages/Theme'
import { ChakraProvider } from '@chakra-ui/react'

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <AppRoutes />
      </ChakraProvider>
    </AuthProvider>
  </BrowserRouter>
)

export default App
