import { Fieldset } from '@/components/Common'
import { Button, Label } from '@/components/Form'
import { DateField, MoneyField, SelectField, TextField, TextareaField } from '@/components/Form/Fields'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { optionsUnits } from '../constants'
import { useEffect, useState } from 'react'
import { useExamUpload } from '@/pages/Exam/hooks'
import { formatBytes } from '@/utils'
import { AiOutlineCloudUpload } from 'react-icons/all'
import { RiDeleteBin2Fill } from 'react-icons/ri'
import { useToast } from '@chakra-ui/react'

export const FormExam = ({
  handleSubmit,
  errors,
  values,
  handleChange,
  dataEulogists,
  setValueInput,
  loadingEditAddExam,
  onChangeFiles,
  files,
  setFiles,
  isButtonDisabled,
  setButtonDisabled,
}) => {
  const toast = useToast()
  const navigate = useNavigate()
  const [indexLoading, setIndexLoading] = useState([])
  const { dataExamUpload, loadingExamUpload, uploadExam, errorExamUpload } = useExamUpload()

  const onChangeInputFile = e => {
    const newFiles = Array.from(e.target.files).map(file => {
      file.uploaded = false
      return file
    })

    setFiles([...files, ...newFiles])
  }

  const handleClickSendFile = async (e, file, index) => {
    const formData = new FormData()
    formData.append(`documents[${index}][archive]`, file)
    formData.append(`documents[${index}][name]`, file.name)
    formData.append(`documents[${index}][size]`, file.size)
    setIndexLoading([...indexLoading, index])
    uploadExam(formData, index)
  }

  const handleDeleteFile = index => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  useEffect(() => {
    if (!dataExamUpload) return
    const newFiles = files.map(file => {
      const fileName = file.name.split('.zip')[0]
      if (file.uploaded) return file
      if (dataExamUpload[0].name === file.name) file.uploaded = true
      if (dataExamUpload[0].name === fileName) file.uploaded = true
      file.link = dataExamUpload[0].link
      return file
    })
    setFiles(newFiles)
    onChangeFiles(newFiles)
    setButtonDisabled(loadingExamUpload || newFiles.some(file => !file.uploaded))
  }, [dataExamUpload])

  useEffect(() => {
    if (!errorExamUpload) return
    if (errorExamUpload.error === 'invalidExtension') {
      toast({
        description: 'Arquivo inválido.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
    }
  }, [errorExamUpload])

  return (
    <form onSubmit={handleSubmit}>
      <section>
        <Fieldset legend='Paciente' gradient className='mt-5 border-2 border-primary bg-surface'>
          <div className='flex flex-col md:flex-row gap-3'>
            <TextField
              noMargins
              errorMessage={errors.patientName}
              value={values.patientName}
              onChange={handleChange}
              name='patientName'
              label='Nome'
              className='w-full'
              placeholder='Ex.: Jefferson Silva'
            />
            <DateField
              noMargins
              errorMessage={errors.patientBirthDate}
              value={values.patientBirthDate}
              onChange={handleChange}
              name='patientBirthDate'
              label='Data de Nascimento'
              className='w-full'
            />
          </div>
        </Fieldset>

        <Fieldset legend='Ficha Médica' gradient className='mt-5 border-2 border-primary bg-surface'>
          <div className='flex flex-col md:flex-row gap-3'>
            <TextField
              noMargins
              errorMessage={errors.requestingDoctor}
              value={values.requestingDoctor}
              onChange={handleChange}
              name='requestingDoctor'
              label='Dr(a) Solicitante'
              className='w-full'
              placeholder='Ex.: Dr(a) Jefferson Silva'
            />
            <TextField
              name='number'
              noMargins
              errorMessage={errors.number}
              value={values.number}
              onChange={handleChange}
              label='Número do Laudo'
              className='w-full'
              placeholder='Ex.: 012345'
            />
            <DateField
              name='date'
              noMargins
              errorMessage={errors.date}
              value={values.date}
              onChange={handleChange}
              label='Data do Exame'
              className='w-full'
            />
          </div>
          <div className='flex flex-col md:flex-row gap-3'>
            <TextField
              name='type'
              noMargins
              errorMessage={errors.type}
              value={values.type}
              onChange={handleChange}
              label='Tipo de Exame'
              className='w-full'
              placeholder='Ex.: Radiografia'
            />
            <MoneyField
              name='costValue'
              noMargins
              errorMessage={errors.costValue}
              value={values.costValue}
              onChange={e => {
                handleChange(e)
                setValueInput(e.numberValue)
              }}
              label='Valor Cobrado (R$)'
              className='w-full'
              placeholder='Ex.: R$ 100,00'
            />
          </div>
          <div className='flex flex-col md:flex-row gap-3'>
            <SelectField
              name='eulogistId'
              noMargins
              errorMessage={errors.eulogistId}
              value={values.eulogistId}
              onChange={handleChange}
              label='Laudador'
              optionPrimary='Selecione o Laudador'
              options={dataEulogists}
              className='w-full'
              placeholder='Ex.: Jefferson Silva'
            />
            <SelectField
              name='unity'
              noMargins
              errorMessage={errors.unity}
              value={values.unity}
              onChange={handleChange}
              label='Unidade'
              optionPrimary='Selecione a Unidade'
              options={optionsUnits()}
              className='w-full'
            />
          </div>
        </Fieldset>
      </section>
      <section>
        <h4 className='font-semibold text-2xl my-3'>Insira aqui os documentos do Exame</h4>
        {files.length === 0 ? (
          <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 w-full hover:bg-surface hover:cursor-pointer border-4 border-dashed border-[#adb5bd] rounded-2 p-3'>
            <Label className='hover:cursor-pointer flex justify-center items-center py-12 w-full gap-3 text-lg col-span-5'>
              <HiOutlineDocumentText className='w-16 h-16' />
              <span className='mr-2 text-blue  underline'>Clique aqui para selecionar o arquivo (Máx.: 2GB)</span>
              <input type='file' onChange={onChangeInputFile} className='hidden' multiple />
            </Label>
          </div>
        ) : (
          <>
            {files.length > 0 && (
              <div className='pb-2'>
                <Label className='hover:cursor-pointer flex justify-center items-center w-full gap-3 text-lg col-span-5 hover:opacity-70'>
                  <HiOutlineDocumentText className='w-10 h-10' />
                  <span className='mr-2 text-blue  underline'>Clique aqui para selecionar mais arquivos (Máx.: 2GB)</span>
                  <input type='file' onChange={onChangeInputFile} className='hidden' multiple />
                </Label>
              </div>
            )}
            <ul className='flex flex-col gap-2 p-4 hover:bg-surface hover:cursor-pointer border-4 border-dashed border-[#adb5bd] rounded-lg'>
              {files.map((file, index) => (
                <>
                  <li key={index} className='flex gap-3 items-center'>
                    {!file.uploaded && (
                      <Button
                        isLoading={indexLoading.includes(index) && loadingExamUpload}
                        isDisabled={indexLoading.includes(index) && loadingExamUpload}
                        className='flex gap-2 items-center bg-primary-light hover:bg-secondary text-white hover:cursor-pointer'
                        onClick={e => handleClickSendFile(e, file, index)}
                      >
                        <AiOutlineCloudUpload size={28} />
                        <span>Enviar Arquivo ({formatBytes(file.size)})</span>
                      </Button>
                    )}
                    {file.uploaded ? (
                      <a
                        href={`${import.meta.env.VITE_EXAM_EXAMS_URL}/${file.link}`}
                        className='text-blue underline hover:cursor-pointer'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {file.name}
                      </a>
                    ) : (
                      <span>{file.name}</span>
                    )}
                    {file.uploaded && <span className='font-bold'>Upload Feito!</span>}
                    {!file.uploaded && (
                      <RiDeleteBin2Fill
                        className='hover:bg-negative-dark text-4xl text-red-600 hover:cursor-pointer'
                        onClick={() => handleDeleteFile(index)}
                      />
                    )}
                  </li>
                  {indexLoading.includes(index) && loadingExamUpload && (
                    <li>
                      <small>ATENÇÃO: o Upload pode demorar dependendo do tamanho do arquivo</small>
                    </li>
                  )}
                </>
              ))}
            </ul>
          </>
        )}
      </section>
      <section>
        <h4 className='font-semibold text-2xl my-3'>Observações</h4>
        <TextareaField
          label=''
          name='observations'
          placeholder='Ex: Paciente está com sintomas a, b e c...'
          value={values.observations}
          onChange={handleChange}
          errorMessage={errors.observations}
        />
      </section>
      <div className='flex justify-end font-semibold gap-1 mt-2'>
        <Button
          type='button'
          className='bg-transparent text-gray-500 hover:bg-gray-200 hover:cursor-pointer'
          onClick={() => navigate(-1)}
        >
          <span className='text-gray-500'>Voltar</span>
        </Button>
        <Button
          type='submit'
          className='bg-primary-light hover:bg-secondary text-white hover:cursor-pointer'
          isLoading={loadingEditAddExam}
          isDisabled={isButtonDisabled}
        >
          Salvar
        </Button>
      </div>
    </form>
  )
}
