import * as Yup from 'yup'
import { useFormik } from 'formik'

export const useFormAssessment = ({ onSubmit }) => {
  const Schema = Yup.object({
    value: Yup.string().required('Campo Obrigatório'),
    pass: Yup.string().required('Campo Obrigatório'),
  })

  return useFormik({
    initialValues: {
      value: '',
      pass: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
