import { Header } from '@/components/Menu/Header'
import { MobileMenu } from '@/components/Menu/MenuMobile'
import React from 'react'
import { ModalChangePassword } from '@/pages/ChangePassword/ModalChangePassword'
import { useModalPass } from './ChangePassword/hooks'

export const Layout = ({ children }) => {
  const { isOpen, onClose, onOpen } = useModalPass()

  return (
    <div className='flex flex-col w-full h-full min-h-screen bg-background text-text'>
      <div className='hidden md:block'>
        <Header handleOpenModalChangePass={onOpen} />
      </div>
      <MobileMenu onOpen={onOpen} />
      <div className='grow min-h-screen max-w-[1440px] overflow-x-hidden md:overflow-x-visible mx-auto p-3 w-full bg-background text-text'>
        {children}
      </div>
      <iframe frameBorder='0' scrolling='no' src='https://astrotech.solutions/assinatura/light.html' height='66px' width='100%' />

      <ModalChangePassword isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </div>
  )
}
