import { Button } from '@chakra-ui/react'
import { AiOutlineSearch } from 'react-icons/all'
import {DateField, SelectField, TextField} from '@/components/Form/Fields'
import { Fieldset } from '@/components/Common'
import { optionsStatusExam, optionsUnits } from '../constants'
import {AiOutlineUserDelete, AiTwotoneDelete} from "react-icons/ai";

export const SearchFormDocument = ({ values, handleChange, handleSubmit, resetForm, loadingExam, errors, dataEulogists }) => {
  return (
    <Fieldset gradient legend='Pesquisar' className='mt-4 bg-surface border-2 border-primary'>
      <form className='flex flex-col md:flex-row gap-2 items-end' onSubmit={handleSubmit}>
          <DateField
            value={values.initialDate}
            errorMessage={errors.initialDate}
            label='Data Inicial'
            name='initialDate'
            onChange={handleChange}
            className='w-full'
          />
          <DateField
            value={values.endDate}
            errorMessage={errors.endDate}
            label='Data Final'
            name='endDate'
            onChange={handleChange}
            className='w-full'
          />
        <Button
          type='submit'
          bg='gray.200'
          color='gray.600'
          _hover={{ bg: 'gray.300' }}
          className='flex items-center text-center justify-center gap-2 w-full md:w-[30rem] lg:w-100 mt-2'
        >
          <AiOutlineSearch size={20} />
          Buscar
        </Button>
      </form>
    </Fieldset>
  )
}
