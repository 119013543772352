import { Button } from '@/components/Form'
import { MoneyField, PasswordField } from '@/components/Form/Fields'

export const FormAssessment = ({ handleSubmit, errors, values, handleChange, setValueInput, loadingSend }) => {
  return (
    <form className='flex flex-col gap-2 items-center justify-center w-full' onSubmit={handleSubmit}>
      <MoneyField
        errorMessage={errors.value}
        value={values.value}
        name='value'
        onChange={e => {
          handleChange(e)
          setValueInput(e.numberValue)
        }}
        className='w-full'
        noMargins
        label='Valor do Serviço (R$)'
        placeholder='R$ 100,00'
      />
      <PasswordField
        errorMessage={errors.pass}
        value={values.pass}
        name='pass'
        onChange={handleChange}
        className='w-full'
        noMargins
        label='Senha'
        placeholder='********'
      />

      <Button isLoading={loadingSend} className='h-fit' text='Salvar' type='submit' />
    </form>
  )
}
