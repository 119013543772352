import { Box, Text } from '@chakra-ui/react'
import classNames from 'classnames'

export const Fieldset = ({ legend, children, className, gradient = false }) => {
  return (
    <Box className={classNames('flex flex-col border rounded-md px-3 py-3', className)}>
      <Text
        fontSize='xl'
        className={classNames('text-gray-700 tracking-tight -mt-9 mb-2 mx-2 px-2 z-20 w-fit bg-surface', {
          'bg-gradient-to-b from-background to-surface': gradient,
        })}
      >
        {legend}
      </Text>
      {children}
    </Box>
  )
}
