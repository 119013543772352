import { Badge } from '@/components/Common'
import { getStatusExam } from '../constants'

export const StatusBadgeExam = ({ status }) => {
  let color

  switch (status) {
    case 1:
      color = 'bg-gray-400'
      break
    case 2:
      color = 'bg-yellow-500'
      break
    case 3:
      color = 'bg-[#168aad]'
      break
    case 4:
      color = 'bg-purple-500'
      break
    case 5:
      color = 'bg-positive'
      break
    case 6:
      color = 'bg-negative'
      break
    default:
      break
  }
  return <Badge bg={color} text={getStatusExam(status)} />
}
