import { FaPenAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'
import { formatPhone } from '@/utils'
import { Loader } from '@/components/Common'
import { BiTrash } from 'react-icons/bi'

export const TableEulogist = ({ data, loadingEulogist, loadingDeleteEulogist, handleDeleteEulogist }) => {
  if (loadingEulogist || loadingDeleteEulogist) return <Loader />

  if (data && data.length === 0)
    return (
      <div className='bg-negative/30 border border-solid border-negative text-negative p-3 mt-3 rounded-2'>
        <p className='font-bold'>Nenhum registro encontrado.</p>
      </div>
    )

  return (
    <table className='w-full mt-3 whitespace-nowrap table-auto'>
      <thead className='bg-primary text-white border'>
        <tr>
          <th className='p-2 w-[50px]'>CRO</th>
          <th className='p-2 bg-primary text-white'>Nome</th>
          <th className='p-2 w-[30px]'>Telefone</th>
          <th className='p-2 w-[30px]'>Email</th>
          <th className='p-2 w-[30px]'>Ações</th>
        </tr>
      </thead>
      <tbody className='border-x'>
        {data.map((item, index) => {
          return (
            <tr key={index} className='text-left border-b bg-surface hover:bg-[#bfd8bd]'>
              <td className='p-[7px] border-x truncate '>{item.cro}</td>
              <td className='p-[7px] border-x truncate '>{item.name}</td>
              <td className='p-[7px] border-x text-center  '>{formatPhone(item.phone)}</td>
              <td className='p-[7px] border-x text-center  '>{item.email}</td>
              <td className='text-center space-x-2 px-3'>
                <Tooltip hasArrow label='Editar Laudador'>
                  <Button as={Link} to={`/eulogist/edit-eulogist/${item.id}`} size='xs'>
                    <FaPenAlt />
                  </Button>
                </Tooltip>
                <Tooltip hasArrow label='Excluir Laudador'>
                  <Button size='xs' onClick={() => handleDeleteEulogist(item.id)}>
                    <BiTrash className='text-negative' />
                  </Button>
                </Tooltip>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
