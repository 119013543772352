import * as Yup from 'yup'
import { useFormik } from 'formik'

export const useFormEditAddExam = ({ onSubmit }) => {
  const Schema = Yup.object({
    patientName: Yup.string().required('Campo Obrigatório.'),
    patientBirthDate: Yup.string().required('Campo Obrigatório.'),
    number: Yup.string().required('Campo Obrigatório.'),
    date: Yup.string().required('Campo Obrigatório.'),
    type: Yup.string().required('Campo Obrigatório.'),
    costValue: Yup.string().required('Campo Obrigatório.'),
    eulogistId: Yup.string().required('Campo Obrigatório.'),
    unity: Yup.string().required('Campo Obrigatório.'),
    observations: Yup.string().nullable(),
    requestingDoctor: Yup.string().required('Campo Obrigatório.'),
  })
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()
  const formattedDate = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
  return useFormik({
    initialValues: {
      requestingDoctor: '',
      patientName: '',
      patientBirthDate: '',
      number: '',
      date: formattedDate,
      type: '',
      costValue: '',
      eulogistId: '',
      unity: '',
      observations: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
