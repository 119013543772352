import { Badge } from '@/components/Common'
import { getTransactionStatus } from '@/pages/Exam/constants'

export const StatusBadgeTransactions = ({ status }) => {
  let color

  switch (status) {
    case 1:
      color = 'bg-gray-400'
      break
    case 2:
      color = 'bg-positive'
      break
    default:
      break
  }
  return <Badge bg={color} text={getTransactionStatus(status)} />
}
