import { VscCopy } from 'react-icons/vsc'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useToast } from '@chakra-ui/react'

const ReferenceLink = ({ indicationUrl = '' }) => {
  const toast = useToast()
  return (
    <>
      <CopyToClipboard
        text={indicationUrl}
        onCopy={() =>
          toast({
            description: 'Copiado com sucesso!',
            status: 'success',
            duration: 2500,
            isClosable: true,
          })
        }
      >
        <div className='flex p-4 rounded gap-2 items-center justify-center bg-primary-light w-full text-center cursor-pointer'>
          <div className=' text-lg'>
            <VscCopy />
          </div>
          <div className='text-md truncate'>{indicationUrl}</div>
        </div>
      </CopyToClipboard>
    </>
  )
}

export default ReferenceLink
