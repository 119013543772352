import { Loading } from '@/components/Common'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { FormEulogist } from './components'
import Swal from 'sweetalert2'
import { useAddress, useEditAddEulogist, useFetchEulogistDetail, useFormEditAddEulogist } from './hooks'

export const EditAddEulogist = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { id } = useParams()
  const { dataEditAddEulogist, errorEditAddEulogist, loadingEditAddEulogist, makeRequestEditAddEulogist } = useEditAddEulogist()
  const { dataEulogist, loadingEulogist, makeRequestEulogist } = useFetchEulogistDetail()
  const { dataZipCode, loadingCity, loadingUf, cityOptions, ufOptions, makeRequestCity, myRequestZipCode } = useAddress()
  const { handleSubmit, errors, values, handleChange, setFieldValue, setFieldError } = useFormEditAddEulogist({
    onSubmit: values => {
      makeRequestEditAddEulogist(
        {
          ...values,
          addressZipCode: values.zipCode,
          addressCity: values.city,
          addressState: values.state,
          addressStreet: values.address,
          addressNeighborhood: values.neighborhood,
          addressComplement: values.complement,
          addressNumber: values.number,
        },
        id
      )
    },
  })

  useEffect(() => {
    if (!dataEditAddEulogist) return
    Swal.fire({
      title: 'Tudo certo!',
      text: `Laudador ${id ? 'editado' : 'criado'} com sucesso!`,
      icon: 'success',
      confirmButtonText: 'Voltar à lista de laudadores',
    }).then(result => {
      if (result.isConfirmed) {
        navigate('/eulogist')
      }
    })
  }, [dataEditAddEulogist])

  useEffect(() => {
    if (!errorEditAddEulogist) return
    if (errorEditAddEulogist.error === 'cpfAlreadyInUse') {
      toast({
        description: 'CPF já está em uso.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
      return
    }
    if (errorEditAddEulogist.error === 'croAlreadyInUse') {
      toast({
        description: 'CRO já está em uso.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
      return
    }
    if (errorEditAddEulogist.error === 'emailAlreadyInUse') {
      toast({
        description: 'E-mail já está em uso.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
      return
    }
    if (errorEditAddEulogist.error === 'cpfInvalid') {
      toast({
        description: 'CPF inválido.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
      return
    }
  }, [errorEditAddEulogist])

  useEffect(() => {
    if (id) makeRequestEulogist(id)
  }, [])

  useEffect(() => {
    if (!dataEulogist) return
    setFieldValue('name', dataEulogist.name)
    setFieldValue('email', dataEulogist.email)
    setFieldValue('phone', dataEulogist.phone)
    setFieldValue('cpf', dataEulogist.cpf)
    setFieldValue('cro', dataEulogist.cro)
    setFieldValue('zipCode', dataEulogist.addressZipCode)
    setFieldValue('city', dataEulogist.addressCity)
    setFieldValue('state', dataEulogist.addressState)
    setFieldValue('address', dataEulogist.addressStreet)
    setFieldValue('neighborhood', dataEulogist.addressNeighborhood)
    setFieldValue('complement', dataEulogist.addressComplement)
    setFieldValue('number', dataEulogist.addressNumber)
  }, [dataEulogist])

  useEffect(() => {
    if (!dataZipCode) return
    if (dataZipCode.erro) {
      setFieldError('zipCode', 'CEP não encontrado. Preencha os campos manualmente.')
      setFieldValue('city', '')
      setFieldValue('state', '')
      setFieldValue('address', '')
      setFieldValue('neighborhood', '')
      return
    }
    setFieldError('zipCode', '')
    setFieldValue('city', dataZipCode.localidade)
    setFieldValue('state', dataZipCode.uf)
    setFieldValue('address', dataZipCode.logradouro)
    setFieldValue('neighborhood', dataZipCode.bairro)
  }, [dataZipCode])

  useEffect(() => {
    if (values.state === '') return
    makeRequestCity(values.state)
  }, [values.state])

  if (loadingEulogist) return <Loading />
  return (
    <div className='mb-4'>
      <h1 className='font-semibold text-2xl mb-3'>{id ? 'Editar Laudador' : 'Novo Laudador'}</h1>
      <FormEulogist
        errors={errors}
        loadingEditAddEulogist={loadingEditAddEulogist}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        values={values}
        cityOptions={cityOptions}
        ufOptions={ufOptions}
        myRequestZipCode={myRequestZipCode}
        loadingUf={loadingUf}
        loadingCity={loadingCity}
      />
    </div>
  )
}
