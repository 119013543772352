import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useSendEmail = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('sendEmail', { id: id })
    return httpClient[method](route)
  })
  return {
    dataEmail: data,
    loadingEmail: loading,
    errorEmail: error,
    makeRequestEmail: makeRequest,
  }
}
