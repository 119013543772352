import { Label } from '@/components/Form'
import { AiOutlineUpload } from 'react-icons/ai'
import { HiOutlineDocumentText } from 'react-icons/hi'

export const DocumentSection = ({ files, onChangeInputFile, setFiles, onOpenEulogist }) => {
  return (
    <>
      <h4 className='font-semibold text-2xl my-3'>Insira aqui os documentos do Laudo</h4>
      {files.length === 0 ? (
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 w-full border rounded-2 p-3'>
          <Label className='hover:cursor-pointer flex justify-center items-center py-12 w-full gap-3 text-lg col-span-5'>
            <HiOutlineDocumentText className='w-16 h-16' />
            <span className='mr-2 text-blue underline'>Clique aqui para selecionar arquivos</span>
            <input type='file' multiple onChange={onChangeInputFile} className='hidden' />
          </Label>
        </div>
      ) : (
        <>
          <div className='flex flex-col md:flex-row justify-between items-center'>
            <Label className='hover:cursor-pointer flex items-center w-fit gap-2 text-lg'>
              <AiOutlineUpload className='w-8 h-8' />
              <span className='mr-2 text-blue underline hover:opacity-70'>Clique aqui para selecionar outros arquivos</span>
              <input type='file' multiple onChange={onChangeInputFile} className='hidden' />
            </Label>
            <span className='text-negative hover:cursor-pointer mb-2 md:mb-0 hover:opacity-70' onClick={() => setFiles([])}>
              Excluir todas os arquivos
            </span>
          </div>
          <div className='flex items-center justify-center w-full gap-2 border rounded-2 p-3'>
            <HiOutlineDocumentText className='w-16 h-16' />
            <span onClick={() => onOpenEulogist()} className='hover:cursor-pointer mr-2 text-blue underline hover:opacity-70'>
              {files.length === 1 ? `${files.length} Arquivo Selecionado` : `${files.length} Arquivos Selecionados`}
            </span>
          </div>
          <span onClick={() => onOpenEulogist()} className='underline hover:cursor-pointer hover:opacity-70'>
            Clique aqui para visualizar os arquivos
          </span>
        </>
      )}
    </>
  )
}
