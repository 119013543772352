import { useNavigate } from 'react-router-dom'
import { Button } from '../Form'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export const ButtonBackSave = (isDisabled, classesNameBack, classesNameSave, textBack = 'Voltar', textSave = 'Salvar') => {
  const navigate = useNavigate()
  return (
    <div className='flex justify-end font-semibold gap-1 mt-2'>
      <Button
        disabled={isDisabled}
        type='button'
        className={classNames('bg-transparent text-gray-500 hover:bg-gray-200 hover:cursor-pointer', classesNameBack)}
        onClick={() => navigate(-1)}
      >
        {textBack}
      </Button>
      <Button
        disabled={isDisabled}
        type='submit'
        className={classNames('bg-primary hover:bg-secondary text-white hover:cursor-pointer', classesNameSave)}
      >
        {textSave}
      </Button>
    </div>
  )
}

ButtonBackSave.propTypes = {
  isDisabled: PropTypes.bool,
  classesNameBack: PropTypes.string,
  classesNameSave: PropTypes.string,
  textBack: PropTypes.string,
  textSave: PropTypes.string,
}

ButtonBackSave.defaultProps = {
  textBack: 'Voltar',
  textSave: 'Salvar',
  isDisabled: false,
  classesNameBack: '',
  classesNameSave: '',
}
