import { FaPenAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { DateAsText, Loader } from '@/components/Common'
import { Tooltip } from '@chakra-ui/react'
import { StatusBadgeExam } from '.'
import { HiOutlinePaperAirplane } from 'react-icons/hi'
import { IoIosCheckmarkCircle, IoMdPhotos } from 'react-icons/io'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { RiErrorWarningFill } from 'react-icons/ri'
import { FiCopy } from 'react-icons/fi'
import { BiTrash } from 'react-icons/bi'

export const TableExam = ({
  data,
  sendEmail,
  openModal,
  approveExam,
  idLoading,
  reproveExam,
  openModalLink,
  loadingReprove,
  loadingApprove,
  loadingEmail,
  loadingExam,
  hardDeleteExam,
  loadingDelete,
}) => {
  if (loadingReprove || loadingApprove || loadingEmail || loadingExam) return <Loader />

  if (data && data.length === 0)
    return (
      <div className='bg-negative/30 border border-solid border-negative text-negative p-3 mt-3 rounded-2'>
        <p className='font-bold'>Nenhum registro encontrado.</p>
      </div>
    )

  return (
    <table className='w-full mt-3 whitespace-nowrap table-auto'>
      <thead className='bg-primary text-white border'>
        <tr>
          <th className='p-2 w-[50px] bg-primary text-white'>N° Laudo</th>
          <th className='p-2'>Paciente</th>
          <th className='p-2 w-[80px]'>Status</th>
          <th className='p-2 w-[30px]'>Unidade</th>
          <th className='p-2 w-[30px]'>Laudador</th>
          <th className='p-2 w-[30px]'>Data</th>
          <th className='p-2 w-[30px]'>Ações</th>
        </tr>
      </thead>
      <tbody className='border-x'>
        {data.map((item, index) => {
          return (
            <tr key={index} className='text-left border-b bg-surface hover:bg-primary-light2'>
              <td className='p-[7px] border-x truncate  text-center'>{item.number}</td>
              <td className='p-[7px] border-x truncate '>{item.patient.name}</td>
              <td className='p-[7px] border-x text-center  '>{<StatusBadgeExam status={item.status} />}</td>
              <td className='p-[7px] border-x text-center  '>{item.unity}</td>
              <td className='p-[7px] border-x'>{item.eulogist.name}</td>
              <td className='p-[7px] border-x'>{<DateAsText date={item.createdAt} type='datetime' />}</td>
              <td className='text-center space-x-2 px-2'>
                {[2, 3, 4].includes(item.status) && (
                  <Tooltip hasArrow label='Link do laudo'>
                    <Button
                      onClick={() => {
                        openModalLink(item.id, item.avaliation)
                      }}
                      size='xs'
                    >
                      <FiCopy className='w-[14px] h-[14px] mb-1' />
                    </Button>
                  </Tooltip>
                )}
                {[4].includes(item.status) && (
                  <>
                    <Tooltip hasArrow label='Aprovar Exame'>
                      <Button onClick={() => approveExam(item.id)} size='xs'>
                        {loadingApprove && idLoading === item.id ? (
                          <AiOutlineLoading3Quarters className='animate-spin h-[14px]' />
                        ) : (
                          <IoIosCheckmarkCircle className='text-positive w-[14px] h-[14px]' />
                        )}
                      </Button>
                    </Tooltip>
                    <Tooltip hasArrow label='Reprovar Exame'>
                      <Button onClick={() => reproveExam(item.id)} size='xs'>
                        {loadingReprove && idLoading === item.id ? (
                          <AiOutlineLoading3Quarters className='animate-spin h-[14px]' />
                        ) : (
                          <RiErrorWarningFill className='text-negative w-[14px] h-[14px]' />
                        )}
                      </Button>
                    </Tooltip>
                  </>
                )}
                <Tooltip hasArrow label='Arquivos do Laudador'>
                  <Button onClick={() => openModal(item.id)} size='xs'>
                    <IoMdPhotos className='w-[14px] h-[14px] mb-1' />
                  </Button>
                </Tooltip>
                {[1, 2, 3].includes(item.status) && (
                  <Tooltip hasArrow label='Enviar para e-mail'>
                    <Button onClick={() => sendEmail(item.id)} size='xs'>
                      <HiOutlinePaperAirplane className='rotate-45 w-[14px] h-[14px] mb-1' />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip hasArrow label='Editar Exame'>
                  <Button as={Link} to={`/exam/edit-exam/${item.id}`} size='xs'>
                    <FaPenAlt />
                  </Button>
                </Tooltip>
                <Tooltip hasArrow label='Deletar Exame'>
                  <Button onClick={() => hardDeleteExam(item.id)} size='xs'>
                    {loadingDelete && idLoading === item.id ? (
                      <AiOutlineLoading3Quarters className='animate-spin h-[14px]' />
                    ) : (
                      <BiTrash className='text-negative h-3 w-3' />
                    )}
                  </Button>
                </Tooltip>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
