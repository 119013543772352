import {
  AiOutlineCloseCircle,
  BiMoney,
  FaStethoscope,
  HiOutlineDocumentText,
  IoExitSharp,
  RiLockPasswordLine,
  VscListSelection,
} from 'react-icons/all'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useAuth } from '@/contexts'

export const SidebarMobile = ({ isMenuOpen, setIsMenuOpen, onOpenPassModal }) => {
  const navigate = useNavigate()
  const { setToken } = useAuth()
  return (
    <>
      <div
        className={classNames(
          'w-80 transition-all fixed z-50 top-0 -left-3 h-full overflow-x-hidden overflow-y-scroll flex bg-[#d6ce93] py-3 px-5 rounded-xl',
          {
            'translate-x-0': isMenuOpen,
          },
          { '-translate-x-full': !isMenuOpen }
        )}
      >
        <div className='w-full h-screen'>
          <section className='flex justify-between text-2xl text-[#603808] w-full'>
            <span>
              <VscListSelection />
            </span>

            <button onClick={() => setIsMenuOpen(false)}>
              <AiOutlineCloseCircle />
            </button>
          </section>

          <div className='flex justify-center'>
            <img src='images/logoBg.png' alt='Logo Central Imagem' className='max-w-[150px] my-4' />
          </div>

          <section className='mb-3'>
            <nav className='flex flex-col gap-3 text-lg text-[#5e3023] py-4 px-2'></nav>
            <a
              onClick={() => {
                setToken(null)
                navigate('/eulogist', { replace: true })
              }}
              className={classNames(
                'flex items-center gap-2 cursor-pointer text-[#5e3023]  text-[1.375rem] hover:text-negative mt-3 pl-2'
              )}
            >
              <FaStethoscope /> Laudadores
            </a>
            <a
              onClick={() => {
                setToken(null)
                navigate('/exam', { replace: true })
              }}
              className={classNames(
                'flex items-center gap-2 cursor-pointer text-[#5e3023]  text-[1.375rem] hover:text-negative mt-3 pl-2'
              )}
            >
              <HiOutlineDocumentText /> Exames
            </a>
            <a
              onClick={() => {
                setToken(null)
                navigate('/financil', { replace: true })
              }}
              className={classNames(
                'flex items-center gap-2 cursor-pointer text-[#5e3023]  text-[1.375rem] hover:text-negative mt-3 pl-2'
              )}
            >
              <BiMoney /> Financeiro
            </a>

            <a
              onClick={() => {
                setIsMenuOpen(false)
                onOpenPassModal()
              }}
              className={classNames(
                'flex items-center gap-2 cursor-pointer text-[#5e3023]  text-[1.375rem] hover:text-negative mt-3 pl-2'
              )}
            >
              <RiLockPasswordLine size={22} />
              Alterar Senha
            </a>

            <a
              onClick={() => {
                setToken(null)
                navigate('/sign-in', { replace: true })
              }}
              className={classNames(
                'flex items-center gap-2 cursor-pointer text-[#5e3023]  text-[1.375rem] hover:text-negative mt-3 pl-2'
              )}
            >
              <IoExitSharp /> Sair
            </a>
          </section>
        </div>
      </div>
      <div
        className={classNames(
          {
            ' w-3/4 border-negative fixed h-full right-0 top-0 lg:hidden z-20 bg-primary/20 backdrop-blur-md transition-all':
              isMenuOpen,
          },
          { ' ': !isMenuOpen }
        )}
        onClick={() => setIsMenuOpen(false)}
      />
    </>
  )
}
