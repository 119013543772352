import { Button } from '@chakra-ui/react'
import { AiOutlineSearch } from 'react-icons/all'
import { TextField } from '@/components/Form/Fields'
import { Fieldset } from '@/components/Common'

export const SearchEulogist = ({ values, handleChange, handleSubmit, resetForm, loadingEulogist, errors }) => {
  return (
    <Fieldset gradient legend='Pesquisar' className='mt-4 bg-surface border-2 border-primary'>
      <form className='flex flex-col md:flex-row gap-2 items-end' onSubmit={handleSubmit}>
        <TextField
          onChange={handleChange}
          noMargins
          className='w-full'
          errorMessage={errors.eulogistName}
          isDisabled={loadingEulogist}
          value={values.eulogistName}
          label='Nome'
          name='eulogistName'
          placeholder='Ex:. 012345'
        />
        <Button
          type='submit'
          bg='gray.200'
          color='gray.600'
          _hover={{ bg: 'gray.300' }}
          className='flex items-center text-center justify-center gap-2 w-full md:w-[30rem] lg:w-100 mt-2'
        >
          <AiOutlineSearch size={20} />
          Buscar
        </Button>
        <Button
          type='button'
          bg='gray.200'
          color='gray.600'
          _hover={{ bg: 'gray.300' }}
          className='text-center w-full md:w-[30rem] lg:w-[400px]'
          onClick={() => resetForm()}
        >
          Limpar filtros
        </Button>
      </form>
    </Fieldset>
  )
}
