import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useFetchEulogistDetail = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('fetchEulogist', { id: id })
    return httpClient[method](route)
  })
  return {
    dataEulogist: data,
    loadingEulogist: loading,
    errorEulogist: error,
    makeRequestEulogist: makeRequest,
  }
}
