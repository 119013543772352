import { Loading } from '@/components/Common'
import { useNavigate, useParams } from 'react-router-dom'
import { useEditAddExam, useFetchEulogist, useFetchExam, useFormEditAddExam } from './hooks'
import { useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { ExamDocModal, FormExam } from './components'
import Swal from 'sweetalert2'

export const EditAddExam = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [files, setFiles] = useState([])
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [exams, setExams] = useState([])
  const [valueInput, setValueInput] = useState([])
  const { dataEulogists, loadingEulogists, makeRequestEulogists } = useFetchEulogist()
  const { dataEditAddExam, errorEditAddExam, loadingEditAddExam, makeRequestEditAddExam } = useEditAddExam()
  const { dataExam, errorExam, loadingExam, makeRequestExam } = useFetchExam()
  const { handleSubmit, errors, values, handleChange, setFieldValue } = useFormEditAddExam({
    onSubmit: () => {
      const { patientName, patientBirthDate, number, date, type, eulogistId, unity, observations, requestingDoctor } = values
      if (exams.length === 0) {
        toast({
          description: 'Campo de documentos é obrigatório. Por favor, selecione um arquivo.',
          status: 'error',
          duration: 2500,
          isClosable: true,
        })
        return
      }
      const formData = new FormData()
      if (!id)
        exams.forEach((exam, index) => {
          formData.append(`documents[${index}][name]`, exam.name)
          formData.append(`documents[${index}][link]`, exam.link)
          formData.append(`documents[${index}][size]`, exam.size)
        })
      if (id) {
        exams.forEach((exam, index) => {
          if (exam.file) return formData.append('newDocuments[]', exam.file)
          formData.append(`documents[${index}][name]`, exam.name)
          formData.append(`documents[${index}][link]`, exam.link)
          formData.append(`documents[${index}][size]`, exam.size)
        })
      }
      formData.append('patientName', patientName)
      formData.append('patientBirthDate', patientBirthDate)
      formData.append('date', date)
      formData.append('type', type)
      formData.append('number', number)
      formData.append('costValue', valueInput)
      formData.append('eulogistId', eulogistId)
      formData.append('unity', unity)
      formData.append('observations', observations)
      formData.append('requesterName', requestingDoctor)
      makeRequestEditAddExam(formData, id && id)
    },
  })

  useEffect(() => {
    if (!errorExam) return
    toast({
      description: 'Houve um erro ao carregar o Exame.',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }, [errorExam])

  useEffect(() => {
    if (!dataExam) return
    setFieldValue('patientName', dataExam.patientName)
    setFieldValue('requestingDoctor', dataExam.requesterName)
    setFieldValue('patientBirthDate', dataExam.patientBirthDate.split(' ')[0])
    setFieldValue('number', dataExam.number)
    setFieldValue('date', dataExam.date.split(' ')[0])
    setFieldValue('type', dataExam.type)
    setFieldValue('costValue', dataExam.costValue)
    setValueInput(dataExam.costValue)
    const DocumentsUploaded = dataExam.documents.map(file => ({
      ...file,
      link: file.link.split('/')[file.link.split('/').length - 1],
      uploaded: true,
    }))
    setFiles(DocumentsUploaded)
    setExams(DocumentsUploaded)
    setFieldValue('eulogistId', dataExam.eulogist?.id)
    setFieldValue('unity', dataExam.unity)
    setFieldValue('observations', dataExam.observations)
    setButtonDisabled(false)
  }, [dataExam])

  useEffect(() => {
    if (!dataEditAddExam) return
    Swal.fire({
      title: 'Tudo certo!',
      text: `Exame ${id ? 'editado' : 'criado'} com sucesso!`,
      icon: 'success',
      confirmButtonText: 'Voltar à lista de exames',
    }).then(result => {
      if (result.isConfirmed) {
        navigate('/exam')
      }
    })
  }, [dataEditAddExam])

  useEffect(() => {
    if (!errorEditAddExam) return
    if (errorEditAddExam.error === 'invalidExtension') {
      toast({
        description: 'Arquivo inválido.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
    }
  }, [errorEditAddExam])

  useEffect(() => {
    if (id) makeRequestExam(id)
    makeRequestEulogists()
  }, [])

  const deleteFile = i => {
    if (exams.length === 1) onClose()
    const deletedExam = exams.filter((e, index) => index !== i)
    setExams(deletedExam)
  }

  const handleChangeFiles = files => {
    setExams(files)
  }

  if (loadingEulogists || loadingExam) return <Loading />
  return (
    <div className='mb-4'>
      <h1 className='font-semibold text-2xl mb-3'>{id ? 'Editar Exame' : 'Novo Exame'}</h1>
      <ExamDocModal trash deleteFile={deleteFile} isOpen={isOpen} onClose={onClose} docs={exams} />
      <FormExam
        setFieldValue={setFieldValue}
        isButtonDisabled={isButtonDisabled}
        setButtonDisabled={setButtonDisabled}
        files={files}
        setFiles={setFiles}
        setValueInput={setValueInput}
        loadingEditAddExam={loadingEditAddExam}
        errors={errors}
        exams={exams}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        onOpen={onOpen}
        setExams={setExams}
        values={values}
        dataEulogists={dataEulogists}
        onChangeFiles={handleChangeFiles}
      />
    </div>
  )
}
