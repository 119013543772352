const operatorsEnum = {
  EQUAL: 'eq',
  LIKE: 'like',
  IN: 'in',
  LESS_THAN: 'lt',
  LESS_THAN_EQUAL: 'lte',
  GREATER_THAN: 'gt',
  GREATER_THAN_EQUAL: 'gte',
  BETWEEN: 'btw',
}

export const prepareFilters = options => {
  const filters = { page: options.page ?? 1 }
  for (let option of options) {
    if (!operatorsEnum[option.operator]) throw new Error(`Invalid column name '${option.operator}'`)
    const filterKey =
      option.operator === 'EQUAL' ? `filter[${option.column}]` : `filter[${option.column}][${operatorsEnum[option.operator]}]`
    const filterValue = Array.isArray(option.value) ? option.value.reduce((value, item) => value + ',' + item) : option.value
    filters[filterKey] = filterValue
  }
  return filters
}
