import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useDeleteExam = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('deleteExam', { id: id })
    return httpClient[method](route)
  })
  return {
    dataDelete: data,
    loadingDelete: loading,
    errorDelete: error,
    makeRequestDelete: makeRequest,
  }
}
