import { useFormik } from 'formik'
import * as Yup from 'yup'

export const useChangePassForm = ({ onSubmit }) => {
  const Schema = Yup.object({
    oldPassword: Yup.string().required('Campo obrigatório'),
    newPassword: Yup.string().required('Campo obrigatório'),
    confirmPassword: Yup.string()
      .required('Campo obrigatório')
      .oneOf([Yup.ref('newPassword'), null], 'As senhas devem ser iguais!'),
  })

  return useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnChange: false,
  })
}
