import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useApproveExam = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('approveExam', { id: id })
    return httpClient[method](route)
  })
  return {
    dataApprove: data,
    loadingApprove: loading,
    errorApprove: error,
    makeRequestApprove: makeRequest,
  }
}
