import { Fieldset } from '@/components/Common'
import { DateField, SelectField } from '@/components/Form/Fields'
import { useFormSearchFinancial } from '../hooks'
import { Button } from '@/components/Form'
import { prepareFilters } from '@/utils'
import { optionsTransactionsStatus, optionsUnits } from '@/pages/Exam/constants'
import { AiOutlineSearch } from 'react-icons/ai'

export const SearchFinancial = ({ makeRequestFinancial, dataEulogists }) => {
  const { values, errors, handleChange, handleSubmit } = useFormSearchFinancial({
    onSubmit: values => {
      const { initialDate, endDate, units, status, eulogist } = values
      const date = endDate && `${initialDate},${endDate}`
      const options = [
        {
          operator: 'EQUAL',
          column: 'people_id',
          value: eulogist,
        },
        {
          operator: 'LIKE',
          column: 'status',
          value: status,
        },
        {
          operator: 'LIKE',
          column: 'exam.unity',
          value: units,
        },
        {
          operator: 'BETWEEN',
          column: 'createdAt',
          value: date,
        },
      ]
      makeRequestFinancial(prepareFilters(options))
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset gradient legend='Pesquisar' className='mt-4 bg-surface border-2 border-primary print-content'>
        <div className='flex flex-col md:flex-row gap-2'>
          <DateField
            value={values.initialDate}
            errorMessage={errors.initialDate}
            label='Data Inicial'
            name='initialDate'
            onChange={handleChange}
            className='w-full'
          />
          <DateField
            value={values.endDate}
            errorMessage={errors.endDate}
            label='Data Final'
            name='endDate'
            onChange={handleChange}
            className='w-full'
          />
        </div>
        <div className='flex gap-2 flex-col md:flex-row'>
          <SelectField
            name='units'
            value={values.units}
            errorMessage={errors.units}
            options={optionsUnits()}
            optionPrimary=':: TODAS ::'
            label='Unidades'
            onChange={handleChange}
            className='w-full'
          />
          <SelectField
            name='status'
            value={values.status}
            errorMessage={errors.status}
            options={optionsTransactionsStatus()}
            label='Status'
            optionPrimary=':: TODOS ::'
            onChange={handleChange}
            className='w-full'
          />
          <SelectField
            name='eulogist'
            value={values.eulogist}
            errorMessage={errors.eulogist}
            options={dataEulogists}
            label='Laudador'
            optionPrimary=':: TODOS ::'
            onChange={handleChange}
            className='w-full'
          />
        </div>
        <Button type='submit' className='flex items-center text-center justify-center gap-2 w-full mt-2 print-button'>
          <AiOutlineSearch size={20} />
          Buscar
        </Button>
      </Fieldset>
    </form>
  )
}
