import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useChangePassword = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('changePassword')
    return httpClient[method](route, { ...params })
  })
  return {
    dataChangePassword: data,
    loadingChangePassword: loading,
    errorChangePassword: error,
    makeRequestChangePassword: makeRequest,
  }
}
