import classNames from 'classnames'

export const FragmentGrid = ({ title, body, className = '' }) => {
  return (
    <div className={classNames('flex flex-col gap-1', className)}>
      <p className='pl-2 bg-gray-100/95 font-semibold'>{title}</p>
      <p className='text-base m-0 pl-2 font-normal'>{body}</p>
    </div>
  )
}
