import * as Yup from 'yup'
import { useFormik } from 'formik'

export const useFormSearchExam = ({ onSubmit }) => {
  const Schema = Yup.object({
    patientName: Yup.string(),
    reportNumber: Yup.string(),
    unit: Yup.string(),
    eulogist: Yup.string(),
    status: Yup.string(),
    initialDate: Yup.string().required('Campo Obrigatório.'),
    endDate: Yup.string().required('Campo Obrigatório.'),
  })

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  const lastDay = lastDayOfMonth.getDate()
  const formattedDateInitial = year + '-' + (month < 10 ? '0' + month : month) + '-' + '01'
  const formattedDateFinal = year + '-' + (month < 10 ? '0' + month : month) + '-' + lastDay

  return useFormik({
    initialValues: {
      patientName: '',
      reportNumber: '',
      unit: '',
      eulogist: '',
      status: '',
      initialDate: formattedDateInitial,
      endDate: formattedDateFinal,
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
