import { DateAsText, Loader, PercentAsText } from '@/components/Common'
import classNames from 'classnames'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Checkbox } from '@chakra-ui/react'
import { StatusBadgeTransactions } from '.'

export const TableFinancial = ({ data, handleCheckChange, loadingFinancial }) => {
  if (loadingFinancial) return <Loader />

  if (data && data.length === 0)
    return (
      <div className='bg-negative/30 border border-solid border-negative text-negative p-3 mt-3 rounded-2'>
        <p className='font-bold'>Nenhum registro encontrado.</p>
      </div>
    )

  return (
    <table className='w-full mt-3 whitespace-nowrap table-auto over'>
      <thead className='bg-primary text-white border print-table print-content'>
        <tr>
          <th className='p-2 w-[50px] text-white'>&nbsp;</th>
          <th className='p-2 w-[50px] text-white'>N° Laudo</th>
          <th className='p-2 text-start'>Paciente</th>
          <th className='p-2 w-[50px]'>Data</th>
          <th className='p-2 w-[80px]'>Status</th>
          <th className='p-2 w-[30px]'>Unidade</th>
          <th className='p-2 text-start'>Laudador</th>
          <th className='p-2 w-[30px] text-right'>Valor Cobrado (R$)</th>
          <th className='p-2 w-[30px] text-right'>Custo (R$)</th>
          <th className='p-2 w-[30px] text-right'>Lucro (R$)</th>
          <th className='p-2 w-[30px] text-right'>Lucro (%)</th>
        </tr>
      </thead>
      <tbody className='border-x'>
        {data.map((item, index) => {
          return (
            <tr key={index} className='text-left border-b bg-surface hover:bg-primary-light3'>
              <td className='text-center px-2'>
                {item.status !== 2 && <Checkbox onChange={handleCheckChange} value={item.id} className='w-fit' />}
              </td>
              <td className='p-[7px] border-x truncate text-center'>{item.exam.number}</td>
              <td className='p-[7px] border-x truncate text-center'>{item.exam.patientName}</td>
              <td className='p-[7px] border-x text-center'>{<DateAsText date={item.createdAt} />}</td>
              <td className='p-[7px] border-x text-center'>{<StatusBadgeTransactions status={item.status} />}</td>
              <td className='p-[7px] border-x text-center'>{item.exam.unity}</td>
              <td className='p-[7px] border-x'>{item.exam.eulogist.name}</td>
              <td className='p-[7px] border-x text-end'>{<MoneyAsText value={item.exam.cost} />}</td>
              <td className='p-[7px] border-x text-end'>-{<MoneyAsText value={item.amount} />}</td>
              <td
                className={classNames('p-[7px] border-x text-end ', {
                  'text-negative': item.profit < 0,
                  'text-positive': item.profit > 0,
                })}
              >
                {<MoneyAsText value={item.profit} />}
              </td>
              <td
                className={classNames('p-[7px] border-x text-end ', {
                  'text-negative': item.profitPercent < 0,
                  'text-positive': item.profitPercent > 0,
                })}
              >
                <PercentAsText value={item.profitPercent} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
