import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { useFetchExam } from '../hooks'
import { Loading } from '@/components/Common'

export const ExamDocModal = ({ id, onClose, isOpen, docs, deleteFile, trash, isTable = false }) => {
  const toast = useToast()
  const [files, setFiles] = useState([])
  const [filesExam, setFilesExam] = useState([])
  const { dataExam, errorExam, loadingExam, makeRequestExam } = useFetchExam()

  const onCloseModal = () => {
    onClose()
  }

  useEffect(() => {
    if (!errorExam) return
    onCloseModal()
    toast({
      description: 'Houve um erro ao carregar os arquivos.',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }, [errorExam])

  useEffect(() => {
    if (!dataExam) return
    if (isTable) {
      const documents = dataExam.eulogistDocuments.map(item => ({ url: item.link, file: { name: item.name } }))
      const documentsExam = dataExam.documents.map(item => ({
        url: item.link.split('/')[item.link.split('/').length - 1],
        file: { name: item.name },
      }))
      setFiles(documents)
      setFilesExam(documentsExam)
      return
    }
    const documents = dataExam.documents.map(item => ({
      url: item.link.split('/')[item.link.split('/').length - 1],
      file: { name: item.name },
    }))
    setFiles(documents)
  }, [dataExam])

  useEffect(() => {
    if (!id) return
    if (isTable) makeRequestExam(id)
  }, [id])

  return (
    <Modal isCentered isOpen={isOpen} size='xl' scrollBehavior='inside' onClose={onCloseModal}>
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='10px' />
      <ModalContent bg='surface'>
        <ModalHeader className='flex justify-between'>
          <Heading as='h2' size='md'>
            {!docs ? `Exame #${dataExam?.number ?? ''}` : 'Arquivos'}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className='flex flex-col gap-2'>
            {loadingExam && <Loading />}
            {docs &&
              docs.map((item, index) => (
                <div key={index} className='flex justify-between bg-gray-100/70 px-3 py-1 items-center'>
                  <a
                    target='_blank'
                    className='hover:opacity-70 hover:cursor-pointer text-lg italic underline'
                    href={`${import.meta.env.VITE_EXAM_EXAMS_URL}/${item.url}`}
                    rel='noreferrer'
                  >
                    {item.file?.name}
                  </a>
                  {trash && (
                    <FiTrash2
                      onClick={() => deleteFile(index)}
                      className='text-negative cursor-pointer hover:opacity-70'
                      size={22}
                    />
                  )}
                </div>
              ))}
            {!loadingExam && dataExam && (
              <>
                {isTable && files.length > 0 && <h3 className='font-bold text-lg'>Documentos do Laudador</h3>}
                {files.map((item, index) => (
                  <div key={index} className='flex justify-between bg-gray-100/70 px-3 py-1 items-center'>
                    <a
                      target='_blank'
                      className='hover:opacity-70 hover:cursor-pointer text-lg italic underline'
                      href={
                        isTable
                          ? `${import.meta.env.VITE_EXAM_EULOGIST_URL}/${item.url.split('/')[item.url.split('/').length - 1]}`
                          : `${import.meta.env.VITE_EXAM_EULOGIST_URL}/${item.url}`
                      }
                      rel='noreferrer'
                    >
                      {item.file?.name}
                    </a>
                  </div>
                ))}
              </>
            )}
            {!loadingExam && dataExam && (
              <>
                {isTable && filesExam.length > 0 && <h3 className='font-bold text-lg'>Documentos do Exame</h3>}
                {filesExam.map((item, index) => (
                  <div key={index} className='flex justify-between bg-gray-100/70 px-3 py-1 items-center'>
                    <a
                      target='_blank'
                      className='hover:opacity-70 hover:cursor-pointer text-lg italic underline'
                      href={`${import.meta.env.VITE_EXAM_EXAMS_URL}/${item.url}`}
                      rel='noreferrer'
                    >
                      {item.file?.name}
                    </a>
                  </div>
                ))}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseModal}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
