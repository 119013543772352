/**
 * ~> Private Route Example:
 * {
 *   path: '/licenses',
 *   pageComponent: ComponentName,
 *   breadcrumb: [{ name: 'Licenças', to: '/licenses' }, { name: 'Consulta' }],
 *   layoutComponent: LoginComponentName, (default: Layout)
 *   enabled: true, (default: true)
 * }
 *
 * ~> Public Route Example:
 * {
 *   path: '/sign-in',
 *   pageComponent: SignIn,
 *   breadcrumb: [],
 *   isPublic: true, (default: false)
 *   layoutComponent: LoginComponentName, (default: Layout)
 *   enabled: true, (default: true)
 * }
 */

import { SignIn } from '@/pages/SignIn'
import { Logout } from '@/pages/Logout'
import { LoginLayout } from './pages/LoginLayout'
import { Exam } from './pages/Exam'
import { EditAddExam } from './pages/Exam/EditAddExam'
import { AssessmentEulogist } from './pages/Eulogist/AssessmentEulogist'
import { Eulogist } from './pages/Eulogist'
import { EditAddEulogist } from './pages/Eulogist/EditAddEulogist'
import { Financial } from './pages/Financial'
import {DocumentExame} from "@/pages/Exam/Document";

export const routes = [
  {
    path: '/',
    pageComponent: SignIn,
    breadcrumb: [],
    isPublic: true,
    layoutComponent: LoginLayout,
  },
  {
    path: '/sign-in',
    pageComponent: SignIn,
    breadcrumb: [],
    isPublic: true,
    layoutComponent: LoginLayout,
  },
  {
    path: '/exam',
    pageComponent: Exam,
    breadcrumb: [],
  },
  {
    path: '/logout',
    pageComponent: Logout,
    isPublic: true,
  },
  {
    path: '/exam/new-exam',
    pageComponent: EditAddExam,
  },
  {
    path: '/exam/edit-exam/:id',
    pageComponent: EditAddExam,
  },

  {
    path: '/assessment-eulogist/:id',
    pageComponent: AssessmentEulogist,
    isPublic: true,
    layoutComponent: LoginLayout,
  },
  {
    path: '/eulogist',
    pageComponent: Eulogist,
  },
  {
    path: '/eulogist/new-eulogist',
    pageComponent: EditAddEulogist,
  },
  {
    path: '/eulogist/edit-eulogist/:id',
    pageComponent: EditAddEulogist,
  },
  {
    path: '/financial',
    pageComponent: Financial,
  },
  {
    path: '/document',
    pageComponent: DocumentExame,
    breadcrumb: [],
  },

]
