import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useFormSignIn = ({ onSubmit }) => {
  const { t } = useTranslation()

  const Schema = Yup.object({
    email: Yup.string().required(t('global.validation.required')).email('E-mail inválido.'),
    password: Yup.string().required(t('global.validation.required')),
    recaptcha: Yup.string().required(t('global.validation.required')).nullable('Campo Obrigatório'),
  })

  return useFormik({
    initialValues: {
      email: '',
      password: '',
      recaptcha: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
