import {useApi, useAxios} from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'
import axios from "axios";
import {useEffect} from "react";

export const useSendAssessment = () => {
  const { data, loading, makeRequest, error } = useAxios((id, pass, params, ts) => {
    const { route: url, method } = getEndpoint('sendAssessment', { id: id, pass: pass, ts: ts })
    return axios({
      method,
      url,
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  })

  return {
    dataSend: data,
    loadingSend: loading,
    errorSend: error,
    makeRequestSend: makeRequest,
  }
}
