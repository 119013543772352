import { Button } from '@/components/Form'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useToast } from '@chakra-ui/react'
import { useChangePassForm } from './hooks/useChangePassForm'
import { PasswordField } from '@/components/Form/Fields'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { useChangePassword } from './hooks'

export const ModalChangePassword = ({ isOpen, onClose, onOpen }) => {
  const { dataChangePassword, errorChangePassword, loadingChangePassword, makeRequestChangePassword } = useChangePassword()
  const toast = useToast()

  const { values, handleSubmit, handleChange, errors, resetForm } = useChangePassForm({
    onSubmit: values => {
      onClose()

      Swal.fire({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#93b1a7',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        reverseButtons: true,
        customClass: 'z-20',
      }).then(result => {
        result.isConfirmed && makeRequestChangePassword({ password: values.oldPassword, ...values })
        result.isDismissed && onOpen()
      })
    },
  })

  const handleClose = () => {
    onClose()

    resetForm()
  }

  useEffect(() => {
    if (!dataChangePassword) return

    toast({
      description: 'Senha alterada com sucesso!',
      status: 'success',
      duration: 2500,
      isClosable: true,
    })
    handleClose()
  }, [dataChangePassword])

  useEffect(() => {
    if (!errorChangePassword) return

    toast({
      description: 'Não foi possivel atualizar sua senha!',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
    handleClose()
  }, [errorChangePassword])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Alterar Senha</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit}>
            <PasswordField
              label='Senha Atual'
              placeholder='******'
              name='oldPassword'
              value={values.oldPassword}
              onChange={handleChange}
              errorMessage={errors.oldPassword}
            />

            <PasswordField
              label='Nova Senha'
              placeholder='******'
              name='newPassword'
              value={values.newPassword}
              onChange={handleChange}
              errorMessage={errors.newPassword}
            />

            <PasswordField
              label='Confirmar Nova Senha'
              placeholder='******'
              name='confirmPassword'
              value={values.confirmPassword}
              onChange={handleChange}
              errorMessage={errors.confirmPassword}
            />

            <div className='flex justify-end mb-2 gap-2'>
              <Button className='bg-transparent hover:bg-gray-100 hover:opacity-70' onClick={handleClose}>
                <span className='text-gray-700'>Cancelar</span>
              </Button>

              <Button type='submit' isLoading={loadingChangePassword}>
                Salvar
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
