import classNames from 'classnames'
import { AiOutlineLoading3Quarters } from 'react-icons/all'

export const Loader = ({ className }) => {
  return (
    <div className={classNames('mt-2 flex justify-center items-center py-4', className)}>
      <AiOutlineLoading3Quarters className='animate-spin h-5 w-5' />
    </div>
  )
}
