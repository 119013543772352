import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useDeleteDocument = () => {
  const { data, loading, makeRequest, error } = useApi(values => {
    const { route, method } = getEndpoint('deleteDocument')
    return httpClient[method](route, {values})
  })
  return {
    dataDelete: data,
    loadingDelete: loading,
    errorDelete: error,
    makeRequestDelete: makeRequest,
  }
}
