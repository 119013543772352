import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useSearchDocument = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('fetchDocument')
    return httpClient[method](`${route}?skipPagination=1&sort=-createdAt`, { params })
  })
  return {
    dataExam: data || [],
    loadingExam: !data || loading,
    errorExam: error,
    makeRequestDocument: makeRequest,
  }
}
