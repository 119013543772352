import { ExamDocModal, ModalCopyLink, SearchFormExam, TableExam } from './components'
import {BsFillPlusCircleFill, BsFillTrashFill, BsWindowDock} from 'react-icons/bs'
import {
  useApproveExam,
  useDeleteExam,
  useFetchEulogist,
  useFormSearchExam,
  useReproveExam,
  useSearchExam,
  useSendEmail,
} from './hooks'
import { useEffect, useState } from 'react'
import { prepareFilters } from '@/utils'
import { Loading } from '@/components/Common'
import { Link } from 'react-router-dom'
import { Button, useDisclosure, useToast } from '@chakra-ui/react'
import Swal from 'sweetalert2'

export const Exam = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenLink, onOpen: onOpenLink, onClose: onCloseLink } = useDisclosure()
  const [id, setId] = useState()
  const [idLoading, setIdLoading] = useState()
  const [link, setLink] = useState()
  const [pass, setPass] = useState()
  const { dataApprove, errorApprove, loadingApprove, makeRequestApprove } = useApproveExam()
  const { dataReprove, errorReprove, loadingReprove, makeRequestReprove } = useReproveExam()
  const { dataDelete, errorDelete, loadingDelete, makeRequestDelete } = useDeleteExam()
  const { dataExam, loadingExam, makeRequestExam } = useSearchExam()
  const { dataEmail, errorEmail, loadingEmail, makeRequestEmail } = useSendEmail()
  const { dataEulogists, loadingEulogists, makeRequestEulogists } = useFetchEulogist()
  const { values, handleSubmit, handleChange, resetForm, errors } = useFormSearchExam({
    onSubmit: values => {
      const { patientName, reportNumber, unit, eulogist, status } = values
      const eulogistName = dataEulogists.find(item => {
        if (item.value === eulogist) return item.label
      })
      const options = [
        {
          operator: 'LIKE',
          column: 'patientName',
          value: patientName,
        },
        {
          operator: 'LIKE',
          column: 'number',
          value: reportNumber,
        },
        {
          operator: 'LIKE',
          column: 'eulogist.name',
          value: eulogistName?.label,
        },
        {
          operator: 'LIKE',
          column: 'unity',
          value: unit,
        },
        {
          operator: 'EQUAL',
          column: 'status',
          value: status,
        },
      ]
      makeRequestExam(prepareFilters(options))
    },
  })

  const openModal = id => {
    setId(id)
    onOpen()
  }

  const openModalLink = (id, avaliation) => {
    setLink(avaliation.link)
    setPass(avaliation.pass)
    onOpenLink()
  }

  const approveExam = id => {
    setIdLoading(id)
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(result => {
      if (result.isConfirmed) {
        makeRequestApprove(id)
      }
    })
  }

  const reproveExam = id => {
    setIdLoading(id)
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(result => {
      if (result.isConfirmed) {
        makeRequestReprove(id)
      }
    })
  }

  const hardDeleteExam = id => {
    setIdLoading(id)
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(result => {
      if (result.isConfirmed) {
        makeRequestDelete(id)
      }
    })
  }

  useEffect(() => {
    if (!dataEmail) return
    toast({
      description: 'Exame enviado com sucesso.',
      status: 'success',
      duration: 2500,
      isClosable: true,
    })
    makeRequestExam()
  }, [dataEmail])

  useEffect(() => {
    if (!errorEmail) return
    toast({
      description: 'Erro ao enviar exame. Tente novamente.',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }, [errorEmail])

  useEffect(() => {
    if (!dataDelete) return
    toast({
      description: 'Exame deletado com sucesso.',
      status: 'success',
      duration: 2500,
      isClosable: true,
    })
    makeRequestExam()
  }, [dataDelete])

  useEffect(() => {
    if (!errorDelete) return
    toast({
      description: 'Erro ao deletar exame. Tente novamente.',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }, [errorDelete])

  useEffect(() => {
    if (!dataApprove) return
    toast({
      description: 'Exame aprovado com sucesso.',
      status: 'success',
      duration: 2500,
      isClosable: true,
    })
    makeRequestExam()
  }, [dataApprove])

  useEffect(() => {
    if (!errorApprove) return
    toast({
      description: 'Erro ao aprovar exame.',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }, [errorApprove])

  useEffect(() => {
    if (!dataReprove) return
    toast({
      description: 'Exame reprovado com sucesso.',
      status: 'success',
      duration: 2500,
      isClosable: true,
    })
    makeRequestExam()
  }, [dataReprove])

  useEffect(() => {
    if (!errorReprove) return
    toast({
      description: 'Erro ao reprovado exame.',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }, [errorReprove])

  useEffect(() => {
    makeRequestExam()
    makeRequestEulogists()
  }, [])

  if (loadingEulogists) return <Loading />

  return (
    <div className='mb-4'>
      <ExamDocModal isTable isOpen={isOpen} onClose={onClose} id={id} />
      <ModalCopyLink indicationUrl={link} isOpen={isOpenLink} onClose={onCloseLink} pass={pass} />
      <div className='flex justify-between'>
        <h1 className='font-semibold text-2xl mb-3'>Exames</h1>
        <Button
          as={Link}
          leftIcon={<BsWindowDock />}
          bg='#0b20de'
          to='/document'
          color='white'
          className='font-bold'
          _hover={{ bg: 'secondary' }}
        >
          Visualizar Documentos
        </Button>
        <Button
          as={Link}
          leftIcon={<BsFillPlusCircleFill />}
          bg='#adc178'
          to='/exam/new-exam'
          color='white'
          className='font-bold'
          _hover={{ bg: 'secondary' }}
        >
          Novo Exame
        </Button>
      </div>
      <SearchFormExam
        dataEulogists={dataEulogists}
        values={values}
        resetForm={resetForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        loadingExam={false}
        errors={errors}
      />
      <div className='overflow-x-scroll no-scrollbar'>
        <TableExam
          loadingDelete={loadingDelete}
          hardDeleteExam={hardDeleteExam}
          openModalLink={openModalLink}
          loadingReprove={loadingReprove}
          reproveExam={reproveExam}
          idLoading={idLoading}
          loadingApprove={loadingApprove}
          approveExam={approveExam}
          openModal={openModal}
          data={dataExam}
          sendEmail={makeRequestEmail}
          loadingExam={loadingExam}
          loadingEmail={loadingEmail}
        />
      </div>
    </div>
  )
}
