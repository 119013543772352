import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useReproveExam = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('reproveExam', { id: id })
    return httpClient[method](route)
  })
  return {
    dataReprove: data,
    loadingReprove: loading,
    errorReprove: error,
    makeRequestReprove: makeRequest,
  }
}
