import { Loading } from '@/components/Common'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { ExamDocModal } from '../Exam/components'
import { useLocation, useParams } from 'react-router-dom'
import { useFormAssessment, useSendAssessment } from './hooks'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { DocumentSection, FormAssessment, InformationSection } from './components'
import { useFetchExam } from './hooks/useFetchExam'

export const AssessmentEulogist = () => {
  const { id } = useParams()
  const { search } = useLocation()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenEulogist, onOpen: onOpenEulogist, onClose: onCloseEulogist } = useDisclosure()
  const [exams, setExams] = useState([])
  const [files, setFiles] = useState([])
  const [valueInput, setValueInput] = useState()
  const { dataExam, loadingExam, makeRequestExam } = useFetchExam()
  const { dataSend, errorSend, loadingSend, makeRequestSend } = useSendAssessment()
  const { errors, values, handleChange, handleSubmit, setFieldValue } = useFormAssessment({
    onSubmit: values => {
      const { pass } = values
      if (files.length === 0) {
        toast({
          description: 'Campo de documentos é obrigatório. Por favor, selecione um arquivo.',
          status: 'error',
          duration: 2500,
          isClosable: true,
        })
        return
      }
      const formData = new FormData()
      files.forEach(file => formData.append('documents[]', file.file))
      formData.append('value', valueInput)
      const ts = search.split('ts=')[1]
      makeRequestSend(id, pass, formData, ts)
    },
  })

  useEffect(() => {
    if (!errorSend) return
    if (errorSend.error === 'invalidExamPassword') {
      toast({
        description: 'Senha inválida.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
      return
    }
    if (errorSend.error === 'invalidExtension') {
      toast({
        description: 'Arquivo inválido.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
      return
    }
  }, [errorSend])

  useEffect(() => {
    if (!dataSend) return
    Swal.fire({
      title: 'Tudo certo!',
      icon: 'success',
      html: 'Avaliação foi Realizada. Você pode fechar essa guia.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
      didOpen: () => {
        const backdrop = document.querySelector('.swal2-backdrop')
        backdrop.style.filter = 'blur(5px)'
      },
    })
  }, [dataSend])

  const deleteFile = i => {
    if (files.length === 1) onCloseEulogist()
    const deletedFile = files.filter((e, index) => index !== i)
    setFiles(deletedFile)
  }

  const onChangeInputFile = e => {
    const copyFiles = [...files]
    for (let i = 0; i < e.target.files.length; i++) {
      copyFiles.push({ file: e.target.files[i], url: URL.createObjectURL(e.target.files[i]) })
    }
    setFiles(copyFiles)
  }

  useEffect(() => {
    if (!dataExam) return
    const documents = dataExam.documents.map(item => ({
      url: item.link.split('/')[item.link.split('/').length - 1],
      file: { name: item.name },
    }))
    setExams(documents)
  }, [dataExam])

  useEffect(() => {
    if (!id) return
    makeRequestExam(id)
  }, [id])

  if (loadingExam || !dataExam)
    return (
      <div className='h-screen'>
        <Loading />
      </div>
    )
  if (dataSend) return

  return (
    <main className='my-5'>
      <ExamDocModal docs={exams} isOpen={isOpen} onClose={onClose} />
      <ExamDocModal trash deleteFile={deleteFile} docs={files} isOpen={isOpenEulogist} onClose={onCloseEulogist} />
      <div className='flex items-center justify-center mt-8'>
        <img className='max-h-52' src='/images/logoBg.png' alt='Logo BG' />
      </div>

      <section className='mt-10'>
        <InformationSection dataExam={dataExam} onOpen={onOpen} />
      </section>

      <DocumentSection files={files} onChangeInputFile={onChangeInputFile} onOpenEulogist={onOpenEulogist} setFiles={setFiles} />
      <section>
        <section className='mt-3'>
          <FormAssessment
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            loadingSend={loadingSend}
            setFieldValue={setFieldValue}
            setValueInput={setValueInput}
            values={values}
          />
        </section>
      </section>
    </main>
  )
}
