import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useFetchEulogist = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('fetchEulogists')
    return httpClient[method](`${route}?skipPagination=1`, { params })
  })
  return {
    dataEulogist: data || [],
    loadingEulogist: !data || loading,
    errorEulogist: error,
    makeRequestEulogist: makeRequest,
  }
}
