import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useFetchEulogist = () => {
  const { data, loading, makeRequest, error } = useApi(() => {
    const { route, method } = getEndpoint('fetchEulogistsOptions')
    return httpClient[method](`${route}?label=name`)
  })
  return {
    dataEulogists: data || [],
    loadingEulogists: !data || loading,
    errorEulogists: error,
    makeRequestEulogists: makeRequest,
  }
}
