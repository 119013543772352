import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const usePayOff = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('transactionsPayOff')
    return httpClient[method](route, { transactions: [...params] })
  })
  return {
    dataPayOff: data,
    loadingPayOff: loading,
    errorPayOff: error,
    makeRequestPayOff: makeRequest,
  }
}
