import * as Yup from 'yup'
import { useFormik } from 'formik'

export const useFormSearchFinancial = ({ onSubmit }) => {
  const Schema = Yup.object({
    initialDate: Yup.string().required('Campo Obrigatório.'),
    endDate: Yup.string().required('Campo Obrigatório.'),
    units: Yup.string(),
    eulogist: Yup.string(),
    type: Yup.string(),
  })

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  const lastDay = lastDayOfMonth.getDate()
  const formattedDateInitial = year + '-' + (month < 10 ? '0' + month : month) + '-' + '01'
  const formattedDateFinal = year + '-' + (month < 10 ? '0' + month : month) + '-' + lastDay

  return useFormik({
    initialValues: {
      initialDate: formattedDateInitial,
      endDate: formattedDateFinal,
      units: '',
      eulogist: '',
      type: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
