import { useAxios } from '@/hooks'
import { getEndpoint } from '@/endpoints'
import axios from 'axios'
import { useAuth } from '@/contexts'
import { useEffect, useState } from 'react'

export const useExamUpload = () => {
  const { token } = useAuth()
  const [response, setResponse] = useState()
  const { data, loading, makeRequest, error } = useAxios(documents => {
    const { route: url, method } = getEndpoint('uploadExamArchive')
    return axios({
      method,
      url,
      data: documents,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      ondownloadprogress: (progressevent) => {
        let percentcompleted = math.round((progressevent.loaded * 100) / progressevent.total);
        console.log(percentcompleted)
      }
    })
  })

  useEffect(() => {
    if (!data) return
    setResponse(data.data)
  }, [data])

  /*const { data, loading, makeRequest, error } = useApi(documents => {
    const { route, method } = getEndpoint('uploadExamArchive')
    return httpClient[method](route, documents)
  })*/
  return {
    dataExamUpload: response,
    loadingExamUpload: loading,
    errorExamUpload: error,
    uploadExam: makeRequest,
  }
}
