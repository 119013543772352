import React from 'react'
import { HiMenu } from 'react-icons/all'
import { useState } from 'react'
import { SidebarMobile } from '@/components/Menu/SidebarMobile'

export const MobileMenu = ({ onOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <>
      <header className='md:hidden flex bg-[#d6ce93] px-3 py-2  items-center'>
        <div>
          <div className='flex justify-center items-center'>
            <button className='text-3xl ' onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <HiMenu className='text-[#5e3023] md:hidden' />
            </button>
          </div>
          <SidebarMobile isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} onOpenPassModal={onOpen} />
        </div>
      </header>
    </>
  )
}
