import { Button } from '@chakra-ui/react'
import { AiOutlineSearch } from 'react-icons/all'
import { SelectField, TextField } from '@/components/Form/Fields'
import { Fieldset } from '@/components/Common'
import { optionsStatusExam, optionsUnits } from '../constants'

export const SearchFormExam = ({ values, handleChange, handleSubmit, resetForm, loadingExam, errors, dataEulogists }) => {
  return (
    <Fieldset gradient legend='Pesquisar' className='mt-4 bg-surface border-2 border-primary'>
      <form className='flex flex-col md:flex-row gap-2 items-end' onSubmit={handleSubmit}>
        <TextField
          onChange={handleChange}
          noMargins
          className='w-full'
          errorMessage={errors.reportNumber}
          isDisabled={loadingExam}
          value={values.reportNumber}
          label='N° Laudo'
          name='reportNumber'
          placeholder='Ex:. 012345'
        />
        <TextField
          onChange={handleChange}
          noMargins
          className='w-full'
          errorMessage={errors.patientName}
          isDisabled={loadingExam}
          value={values.patientName}
          label='Paciente'
          name='patientName'
          placeholder='Ex:. Jefferson Silva'
        />
        <SelectField
          onChange={handleChange}
          noMargins
          className='w-full'
          errorMessage={errors.unit}
          isDisabled={loadingExam}
          value={values.unit}
          options={optionsUnits()}
          label='Unidade'
          optionPrimary=':: TODAS ::'
          name='unit'
        />
        <SelectField
          onChange={handleChange}
          noMargins
          className='w-full'
          errorMessage={errors.eulogist}
          isDisabled={loadingExam}
          value={values.eulogist}
          options={dataEulogists}
          optionPrimary=':: TODOS ::'
          label='Laudador'
          name='eulogist'
        />
        <SelectField
          onChange={handleChange}
          noMargins
          className='w-full'
          errorMessage={errors.status}
          isDisabled={loadingExam}
          value={values.status}
          options={optionsStatusExam()}
          optionPrimary=':: TODOS ::'
          label='Status'
          name='status'
        />
        <Button
          type='submit'
          bg='gray.200'
          color='gray.600'
          _hover={{ bg: 'gray.300' }}
          className='flex items-center text-center justify-center gap-2 w-full md:w-[30rem] lg:w-100 mt-2'
        >
          <AiOutlineSearch size={20} />
          Buscar
        </Button>
        <Button
          type='button'
          bg='gray.200'
          color='gray.600'
          _hover={{ bg: 'gray.300' }}
          className='text-center w-full md:w-[30rem] lg:w-[400px]'
          onClick={() => resetForm()}
        >
          Limpar filtros
        </Button>
      </form>
    </Fieldset>
  )
}
