import { TableEulogist } from './components'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { useDeleteEulogist, useFetchEulogist, useFormSearchEulogist } from './hooks'
import { useEffect } from 'react'
import { prepareFilters } from '@/utils'
import { Link } from 'react-router-dom'
import { Button, useToast } from '@chakra-ui/react'
import { SearchEulogist } from './components/SearchEulogist'
import Swal from 'sweetalert2'

export const Eulogist = () => {
  const toast = useToast()
  const { dataEulogist, loadingEulogist, makeRequestEulogist } = useFetchEulogist()
  const { dataDeleteEulogist, loadingDeleteEulogist, makeRequestDeleteEulogist, errorDeleteEulogist } = useDeleteEulogist()
  const { values, handleSubmit, handleChange, resetForm, errors } = useFormSearchEulogist({
    onSubmit: values => {
      const { eulogistName } = values

      const options = [
        {
          operator: 'LIKE',
          column: 'name',
          value: eulogistName,
        },
      ]
      makeRequestEulogist(prepareFilters(options))
    },
  })

  const handleDeleteEulogist = id => {
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(result => {
      if (result.isConfirmed) {
        makeRequestDeleteEulogist(id)
      }
    })
  }

  useEffect(() => {
    if (!dataDeleteEulogist) return
    makeRequestEulogist()
    toast({
      description: 'Laudador deletado com sucesso.',
      status: 'success',
      duration: 2500,
      isClosable: true,
    })
    makeRequestEulogist()
  }, [dataDeleteEulogist])

  useEffect(() => {
    if (!errorDeleteEulogist) return
    toast({
      description: 'Erro ao deletar Laudador.',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }, [errorDeleteEulogist])

  useEffect(() => {
    makeRequestEulogist()
  }, [])

  return (
    <div className='mb-4'>
      <div className='flex justify-between'>
        <h1 className='font-semibold text-2xl mb-3'>Laudadores</h1>
        <Button
          as={Link}
          leftIcon={<BsFillPlusCircleFill />}
          bg='#adc178'
          to='/eulogist/new-eulogist'
          color='white'
          className='font-bold bg-primary-light hover:bg-secondary'
        >
          Novo Laudador
        </Button>
      </div>
      <SearchEulogist
        values={values}
        resetForm={resetForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        loadingExam={loadingEulogist}
        errors={errors}
      />
      <div className='overflow-x-scroll no-scrollbar'>
        <TableEulogist
          dataDeleteEulogist={dataDeleteEulogist}
          loadingDeleteEulogist={loadingDeleteEulogist}
          handleDeleteEulogist={handleDeleteEulogist}
          errorDeleteEulogist={errorDeleteEulogist}
          loadingEulogist={loadingEulogist}
          data={dataEulogist}
        />
      </div>
    </div>
  )
}
