import { Fieldset } from '@/components/Common'
import { Button } from '@/components/Form'
import { CpfCnpjField, PhoneField, SelectField, TextField, ZipCodeField } from '@/components/Form/Fields'
import { useNavigate } from 'react-router-dom'

export const FormEulogist = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  setFieldValue,
  cityOptions,
  ufOptions,
  myRequestZipCode,
  loadingUf,
  loadingCity,
  loadingEditAddEulogist,
}) => {
  const navigate = useNavigate()

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset gradient legend='Dados Pessoais' className='mt-5 border-2 border-primary bg-surface'>
        <TextField
          className='w-full'
          label='Nome'
          placeholder='Ex.: Jefferson Silva'
          errorMessage={errors.name}
          name='name'
          value={values.name}
          onChange={handleChange}
        />
        <div className='flex gap-3'>
          <TextField
            className='w-full'
            label='Email'
            placeholder='Ex.: jeff@email.com'
            errorMessage={errors.email}
            name='email'
            value={values.email}
            onChange={handleChange}
          />
          <PhoneField
            className='w-full'
            label='Telefone'
            placeholder='Ex.: (85) 98888-8888'
            errorMessage={errors.phone}
            name='phone'
            value={values.phone}
            onChange={handleChange}
          />
        </div>
        <div className='flex gap-3'>
          <CpfCnpjField
            className='w-full'
            label='CPF'
            placeholder='Ex.: 123.456.789.01'
            errorMessage={errors.cpf}
            name='cpf'
            value={values.cpf}
            onChange={handleChange}
          />
          <TextField
            className='w-full'
            label='CRO'
            placeholder='Ex.: 123456'
            errorMessage={errors.cro}
            name='cro'
            value={values.cro}
            onChange={handleChange}
          />
        </div>
      </Fieldset>
      <Fieldset gradient legend='Endereço' className='mt-5 border-2 border-primary bg-surface'>
        <div className='flex flex-col md:flex-row lg:flex-row gap-3 mt-2'>
          <ZipCodeField
            label='CEP'
            name='zipCode'
            placeholder='00000-000'
            className='h-10'
            onValueChange={({ value }) => {
              if (value.length === 8) {
                myRequestZipCode(value)
              }
            }}
            onChange={e => {
              setFieldValue('zipCode', e.target.value)
            }}
            value={values.zipCode}
            errorMessage={errors.zipCode}
            isDisabled={loadingUf}
          />
          <SelectField
            isDisabled={loadingUf}
            label='Estado'
            name='state'
            onChange={handleChange}
            value={values.state}
            options={ufOptions}
            optionPrimary={loadingUf ? 'Carregando...' : 'Selecione o Estado'}
            errorMessage={errors.state}
          />
          <SelectField
            isDisabled={loadingCity || !values.state}
            label='Cidade'
            name='city'
            onChange={handleChange}
            options={cityOptions}
            value={values.city}
            className='text-black'
            optionPrimary={loadingCity ? 'Carregando...' : 'Selecione a Cidade'}
            errorMessage={errors.city}
          />
        </div>
        <div className='flex flex-col md:flex-row gap-3 items-center mt-3'>
          <TextField
            placeholder='Ex:. Rua Augusto Mendes'
            label='Logradouro'
            value={values.address}
            onChange={handleChange}
            name='address'
            className='w-full'
            errorMessage={errors.address}
          />
          <TextField
            className='w-full'
            placeholder='Ex:. 123'
            label='Nº'
            name='number'
            onChange={handleChange}
            errorMessage={errors.number}
            value={values.number}
          />
          <TextField
            placeholder='Ex:. Centro'
            label='Bairro'
            className='w-full'
            name='neighborhood'
            onChange={handleChange}
            value={values.neighborhood}
            errorMessage={errors.neighborhood}
          />
          <TextField
            placeholder='Ex:. Em frente ao Shopping Iguatemi '
            label='Complemento'
            name='complement'
            className='w-full'
            onChange={handleChange}
            value={values.complement}
            errorMessage={errors.complement}
          />
        </div>
      </Fieldset>
      <div className='flex justify-end font-semibold gap-1 mt-2'>
        <Button
          type='button'
          className='bg-transparent text-gray-500 hover:bg-gray-200 hover:cursor-pointer'
          onClick={() => navigate(-1)}
        >
          <span className='text-gray-500'>Voltar</span>
        </Button>
        <Button
          type='submit'
          className='bg-primary-light hover:bg-secondary text-white hover:cursor-pointer'
          isLoading={loadingEditAddEulogist}
        >
          Salvar
        </Button>
      </div>
    </form>
  )
}
