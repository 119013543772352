import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useEditAddExam = () => {
  const { data, loading, makeRequest, error } = useApi((params, id) => {
    const endpointName = id ? 'editExam' : 'addExam'
    const { route, method } = getEndpoint(endpointName, id && { id: id })
    return httpClient[method](route, params)
  })
  return {
    dataEditAddExam: data,
    loadingEditAddExam: loading,
    errorEditAddExam: error,
    makeRequestEditAddExam: makeRequest,
  }
}
