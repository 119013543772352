import { useAxios } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { useEffect, useState } from 'react'

export const useAddress = () => {
  const [ufOptions, setUfOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const { makeRequest: myRequestZipCode, data: dataZipCode } = useAxios(zipCode =>
    httpClient.get(`https://viacep.com.br/ws/${zipCode}/json/`)
  )

  const {
    makeRequest: makeRequestUf,
    data: dataUf,
    loading: loadingUf,
  } = useAxios(() => httpClient.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'))

  const {
    makeRequest: makeRequestCity,
    data: dataCity,
    loading: loadingCity,
  } = useAxios(selectedUf =>
    httpClient.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`)
  )

  const handleOptionsUf = (options = []) => {
    const arr = []
    options?.map(item => {
      arr.push({ label: item.nome, value: item.sigla })
    })
    setUfOptions(arr)
  }

  const handleOptionsCity = (options = []) => {
    const arr = []
    options?.map(item => {
      arr.push({ label: item.nome, value: item.nome })
    })
    setCityOptions(arr)
  }

  useEffect(() => {
    makeRequestUf()
  }, [])

  useEffect(() => {
    if (!dataUf) return
    handleOptionsUf(dataUf)
  }, [dataUf])

  useEffect(() => {
    if (!dataCity) return
    handleOptionsCity(dataCity)
  }, [dataCity])

  return {
    makeRequestCity,
    dataCity,
    dataUf,
    dataZipCode,
    loadingUf,
    loadingCity,
    myRequestZipCode,
    cityOptions,
    ufOptions,
  }
}
