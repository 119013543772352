import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useSearchFinancial = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('fetchTransactions')
    return httpClient[method](`${route}?sort=-createdAt&skipPagination=1`, { params })
  })
  return {
    dataFinancial: data || [],
    loadingFinancial: loading,
    errorFinancial: error,
    makeRequestFinancial: makeRequest,
  }
}
