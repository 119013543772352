import classNames from 'classnames'
import { Link } from 'react-router-dom'

export const NavItem = ({ path, text = '', className, icon }) => {
  return (
    <Link to={path} className={classNames('p-2 hover:text-gray-700/70 font-semibold', className)}>
      {icon ? icon + text : text}
    </Link>
  )
}
