import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useDeleteEulogist = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('deleteEulogist', { id: id })
    return httpClient[method](route)
  })
  return {
    dataDeleteEulogist: data,
    loadingDeleteEulogist: loading,
    errorDeleteEulogist: error,
    makeRequestDeleteEulogist: makeRequest,
  }
}
