import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useEditAddEulogist = () => {
  const { data, loading, makeRequest, error } = useApi((params, id) => {
    const endpointName = id ? 'editEulogist' : 'addEulogist'
    const { route, method } = getEndpoint(endpointName, id && { id: id })
    return httpClient[method](route, { ...params })
  })
  return {
    dataEditAddEulogist: data,
    loadingEditAddEulogist: loading,
    errorEditAddEulogist: error,
    makeRequestEditAddEulogist: makeRequest,
  }
}
