import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useLoginCustomer() {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('sign-in')
    return httpClient[method](route, { ...params })
  })
  return {
    data,
    error,
    loading,
    login: makeRequest,
  }
}
