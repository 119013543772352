import React from 'react'
import { NumericFormat } from 'react-number-format'
import classNames from 'classnames'
import { InputText } from '@/components/Form/InputText'

export const MoneyAsInput = ({ name, value, onChange, precision = 2, currencyCode = 'BRL', ...rest }) => {
  const { className, suffix = '', allowNegative = false, isDisabled = false } = rest

  let prefix = 'R$ '
  let decimalSeparator = ','
  let thousandSeparator = '.'

  if (['USD', 'EUR'].includes(currencyCode)) {
    decimalSeparator = '.'
    thousandSeparator = ','
  }

  if (currencyCode === 'USD') prefix = 'U$ '
  if (currencyCode === 'EUR') prefix = '€ '

  return (
    <NumericFormat
      {...rest}
      allowNegative={allowNegative}
      thousandsGroupStyle='thousand'
      decimalScale={precision}
      onClick={e => e.target.select()}
      fixedDecimalScale={true}
      value={value}
      displayType='input'
      name={name}
      disabled={isDisabled}
      onValueChange={({ floatValue }, sourceInfo) => {
        if (!sourceInfo.event) return
        onChange({ ...sourceInfo.event, numberValue: floatValue })
      }}
      customInput={InputText}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
      suffix={suffix}
      className={classNames(className, { 'text-negative': value < 0 })}
    />
  )
}
