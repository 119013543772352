import { ExamDocModal, ModalCopyLink, SearchFormDocument, TableExam } from './components'
import {BsFillPlusCircleFill, BsFillTrashFill, BsWindowDock} from 'react-icons/bs'
import {
  useApproveExam,
  useDeleteExam,
  useFetchEulogist,
  useFormSearchExam,
  useReproveExam,
  useSearchDocument,
  useSendEmail,
} from './hooks'
import { useEffect, useState } from 'react'
import { prepareFilters } from '@/utils'
import { Loading } from '@/components/Common'
import { Link } from 'react-router-dom'
import {Button, Tooltip, useDisclosure, useToast} from '@chakra-ui/react'
import Swal from 'sweetalert2'
import {TableDocument} from "@/pages/Exam/components/TableDocument";
import {BiTrash} from "react-icons/bi";
import {AiOutlineLoading3Quarters} from "react-icons/all";
import {useDeleteDocument} from "@/pages/Exam/hooks/useDeleteDocument";

export const DocumentExame = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenLink, onOpen: onOpenLink, onClose: onCloseLink } = useDisclosure()
  const [id, setId] = useState()
  const [idLoading, setIdLoading] = useState()
  const [link, setLink] = useState()
  const [pass, setPass] = useState()
  const { dataApprove, errorApprove, loadingApprove, makeRequestApprove } = useApproveExam()
  const { dataReprove, errorReprove, loadingReprove, makeRequestReprove } = useReproveExam()
  const { dataDelete, errorDelete, loadingDelete, makeRequestDelete } = useDeleteDocument()
  const { dataExam, loadingExam, makeRequestDocument } = useSearchDocument()
  const { dataEmail, errorEmail, loadingEmail, makeRequestEmail } = useSendEmail()
  const { dataEulogists, loadingEulogists, makeRequestEulogists } = useFetchEulogist()
  const { values, handleSubmit, handleChange, resetForm, errors } = useFormSearchExam({
    onSubmit: values => {
      const { patientName, reportNumber, unit, eulogist, status, initialDate, endDate } = values
      const date = endDate && `${initialDate},${endDate}`

      const eulogistName = dataEulogists.find(item => {
        if (item.value === eulogist) return item.label
      })
      const options = [
        {
          operator: 'LIKE',
          column: 'patientName',
          value: patientName,
        },
        {
          operator: 'LIKE',
          column: 'number',
          value: reportNumber,
        },
        {
          operator: 'LIKE',
          column: 'eulogist.name',
          value: eulogistName?.label,
        },
        {
          operator: 'LIKE',
          column: 'unity',
          value: unit,
        },
        {
          operator: 'EQUAL',
          column: 'status',
          value: status,
        },
        {
          operator: 'BETWEEN',
          column: 'createdAt',
          value: date,
        },
      ]
      makeRequestDocument(prepareFilters(options))
    },
  })

  const openModal = id => {
    setId(id)
    onOpen()
  }

  const openModalLink = (id, avaliation) => {
    setLink(avaliation.link)
    setPass(avaliation.pass)
    onOpenLink()
  }

  const approveExam = id => {
    setIdLoading(id)
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(result => {
      if (result.isConfirmed) {
        makeRequestApprove(id)
      }
    })
  }

  const reproveExam = id => {
    setIdLoading(id)
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(result => {
      if (result.isConfirmed) {
        makeRequestReprove(id)
      }
    })
  }

  const hardDeleteExam = ()  => {
    setIdLoading()
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(result => {
      if (result.isConfirmed) {
        makeRequestDelete(values).then(()=>{
          window.location.reload()
        })
      }
    })
  }


  useEffect(() => {
    makeRequestDocument()
    makeRequestEulogists()
  }, [])

  if (loadingEulogists) return <Loading />

  return (
    <div className='mb-4'>
      <ExamDocModal isTable isOpen={isOpen} onClose={onClose} id={id} />
      <ModalCopyLink indicationUrl={link} isOpen={isOpenLink} onClose={onCloseLink} pass={pass} />
      <div className='flex justify-between'>
        <h1 className='font-semibold text-2xl mb-3'>Documentos</h1>
        <Tooltip hasArrow label='Deletar Exame'>
        <Button onClick={() => hardDeleteExam()}
                bg='#de240b'
                leftIcon={<BsFillTrashFill />}
                color='white'
                className='font-bold'
                _hover={{ bg: 'secondary' }}
        >
          Excluir Todos Documentos
              </Button>
        </Tooltip>
      </div>
      <SearchFormDocument
        dataEulogists={dataEulogists}
        values={values}
        resetForm={resetForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        loadingExam={false}
        errors={errors}
      />
      <div className='overflow-x-scroll no-scrollbar'>
        <TableDocument
          loadingDelete={loadingDelete}
          hardDeleteExam={useDeleteDocument}
          openModalLink={openModalLink}
          loadingReprove={loadingReprove}
          reproveExam={reproveExam}
          idLoading={idLoading}
          loadingApprove={loadingApprove}
          approveExam={approveExam}
          openModal={openModal}
          data={dataExam}
          sendEmail={makeRequestEmail}
          loadingExam={loadingExam}
          loadingEmail={loadingEmail}
        />
      </div>
    </div>
  )
}
