import { useEffect, useState } from 'react'
import { SearchFinancial } from './components/SearchFinancial'
import { TableFinancial } from './components/TableFinancial'
import { Loading } from '@/components/Common'
import { useSearchFinancial } from './hooks'
import { useFetchEulogist } from '../Exam/hooks'
import { prepareFilters } from '@/utils'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Button } from '@/components/Form'
import classNames from 'classnames'
import { usePayOff } from './hooks/usePayOff'
import Swal from 'sweetalert2'
import { useToast } from '@chakra-ui/react'

export const Financial = () => {
  const toast = useToast()
  const { dataFinancial, loadingFinancial, makeRequestFinancial } = useSearchFinancial()
  const { dataEulogists, makeRequestEulogists, loadingEulogists } = useFetchEulogist()
  const { dataPayOff, errorPayOff, loadingPayOff, makeRequestPayOff } = usePayOff()
  const [idPayOff, setIdPayOff] = useState([])
  const [info, setInfo] = useState({
    cost: 0,
    receipt: 0,
    ticket: 0,
    amountFuture: 0,
    amount: 0,
  })
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const formattedDate = year + '-' + (month < 10 ? '0' + month : month)
  const options = [
    {
      operator: 'BETWEEN',
      column: 'createdAt',
      value: formattedDate,
    },
  ]
  const handlePrint = () => {
    window.print()
  }

  useEffect(() => {
    makeRequestEulogists()
    makeRequestFinancial(prepareFilters(options))
  }, [])

  const handlePayOff = () => {
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(result => {
      if (result.isConfirmed) {
        makeRequestPayOff(idPayOff)
      }
    })
  }

  const handleCheckChange = e => {
    const copyIds = [...idPayOff]
    if (e.target.checked === false) {
      const filter = copyIds.filter(item => item !== e.target.value)
      setIdPayOff(filter)
      return
    }
    copyIds.push(e.target.value)
    setIdPayOff(copyIds)
  }

  useEffect(() => {
    if (!dataPayOff) return
    makeRequestFinancial(prepareFilters(options))
    setIdPayOff([])
    toast({
      description: 'Baixa realizada.',
      status: 'success',
      duration: 2500,
      isClosable: true,
    })
  }, [dataPayOff])

  useEffect(() => {
    if (!errorPayOff) return
    toast({
      description: 'Erro ao dar baixa.',
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }, [errorPayOff])

  useEffect(() => {
    if (!dataFinancial) return
    const cost = dataFinancial.map(item => item.status === 2 && item.exam.cost)
    const calcCost = cost.reduce((acc, value) => acc + value, 0)
    const profit = dataFinancial.map(item => item.status === 2 && item.profit)
    const calcProfit = profit.reduce((acc, value) => acc + value, 0)
    const ticket = dataFinancial.map(item => item.status === 2 && item.exam.cost)
    const costAmount = dataFinancial.map(item => item.status === 2 && item.amount)
    const amount = costAmount.reduce((acc, value) => acc + value, 0)
    const ticketFuture = dataFinancial.map(item => item.status === 1 && item.exam.cost)
    const calcAmountFuture = ticketFuture.reduce((acc, value) => acc + value, 0)
    const statusApprove = dataFinancial.filter(item => item.status === 2)
    const calcTicket = ticket.reduce((acc, value) => acc + value, 0) / statusApprove.length
    setInfo({
      cost: calcCost ?? 0,
      receipt: calcProfit ?? 0,
      ticket: isNaN(calcTicket) ? 0 : calcTicket,
      amount: amount ?? 0,
      amountFuture: calcAmountFuture ?? 0,
    })
  }, [dataFinancial])

  if (loadingEulogists || loadingPayOff) return <Loading />

  return (
    <div className='mb-4'>
      <div className='flex justify-between'>
        <h1 className='font-semibold text-2xl mb-3'>Financeiro</h1>
        <Button text='Imprimir' className='font-bold mt-2 no-print' onClick={() => handlePrint()} />
      </div>
      <SearchFinancial dataEulogists={dataEulogists} makeRequestFinancial={makeRequestFinancial} />
      <Button
        className={classNames('font-bold mt-2', { hidden: idPayOff.length === 0 })}
        text={`Dar Baixa (${idPayOff.length})`}
        onClick={handlePayOff}
      />
      <section
        className={classNames('flex md:flex-row flex-col gap-2 md:gap-0 md:justify-between mt-3 font-semibold', {
          hidden: loadingFinancial,
        })}
      >
        <span className='px-4 py-2 bg-primary-light2 w-fit rounded-2'>N° Laudos: {dataFinancial.length}</span>
        <div className='flex md:flex-row flex-col gap-2'>
          <span className='px-4 py-2 bg-orange-300/50 print-orange w-fit rounded-2'>
            Recebimento Previsto: {<MoneyAsText value={info.amountFuture} />}
          </span>
          <span className='px-4 py-2 bg-negative/30 print-negative w-fit rounded-2'>
            Valor Cobrado: {<MoneyAsText value={info.cost} />}
          </span>
          <span className='px-4 py-2 bg-positive/30 print-positive w-fit rounded-2'>
            Custo: {<MoneyAsText value={info.amount} />}
          </span>
          <span className='px-4 py-2 bg-positive/30 print-positive w-fit rounded-2'>
            Lucro: {<MoneyAsText value={info.receipt} />}
          </span>
          <span className='px-4 py-2 bg-positive/30 print-positive w-fit rounded-2'>
            Ticket Médio: {<MoneyAsText value={info.ticket} />}
          </span>
        </div>
      </section>
      <div className='overflow-x-scroll no-scrollbar'>
        <TableFinancial loadingFinancial={loadingFinancial} handleCheckChange={handleCheckChange} data={dataFinancial} />
      </div>
    </div>
  )
}
