import { extendTheme } from '@chakra-ui/react'
import { MultiSelectTheme } from 'chakra-multiselect'

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
}

export const theme = extendTheme({
  colors: {
    primary: '#444CE7',
    secondary: '#718355',
    surface: '#FFFFFF',
    background: '#F2F4F7',
    black: '#000000',
    white: '#F6FBFD',
    blue: {
      100: '#9ABECC',
      200: '#5B6B7A',
    },
    grayColor: {
      100: '#EAECF0',
      200: '#D0D5DD',
      300: '#98A2B3',
      400: '#657085',
      500: '#475467',
      600: '#344054',
      700: '#1D2939',
    },
    light: '#E0EAFF',
    light2: '#F9FAFB',
    positive: '#039855',
    negative: '#D92D20',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1.125rem',
    xl: '1.5rem',
    '2xl': '1.75rem',
    '3xl': '2rem',
    '4xl': '2.5rem',
    '5xl': '3rem',
    '6xl': '3.5rem',
    '7xl': '3.75rem',
    '8xl': '4rem',
    '9xl': '4.25rem',
  },
  components: {
    MultiSelect: MultiSelectTheme,
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
              ...activeLabelStyles,
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
  },
})
