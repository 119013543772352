import * as Yup from 'yup'
import { useFormik } from 'formik'

export const useFormEditAddEulogist = ({ onSubmit }) => {
  const Schema = Yup.object({
    name: Yup.string().required('Campo obrigatório'),
    typeCustomer: Yup.string(),
    cpf: Yup.string()
      .required('Campo obrigatório')
      .test('minCpf', 'O cpf deve conter 11 caracteres', value => {
        const clearValue = String(value).replace(/[^0-9]/g, '')
        return clearValue.length === 11
      }),
    email: Yup.string().email('Email Inválido').required('Campo obrigatório'),
    phone: Yup.string()
      .required('Campo obrigatório')
      .test('minPhone', 'O telefone deve conter 11 caracteres', value => {
        const clearValue = String(value).replace(/[^0-9]/g, '')
        return clearValue.length === 11
      }),
    cro: Yup.string().required('Campo obrigatório'),
    zipCode: Yup.string()
      .required('Campo obrigatório')
      .test('minPhone', 'O CEP deve conter 8 caracteres', value => {
        const clearValue = String(value).replace(/[^0-9]/g, '')
        return clearValue.length === 8
      }),
    number: Yup.string().required('Campo obrigatório'),
    complement: Yup.string().nullable(),
    neighborhood: Yup.string().required('Campo obrigatório'),
    city: Yup.string().required('Campo obrigatório'),
    state: Yup.string().required('Campo obrigatório'),
    address: Yup.string().required('Campo obrigatório'),
  })

  return useFormik({
    initialValues: {
      cro: '',
      name: '',
      cpf: '',
      email: '',
      phone: '',
      zipCode: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      address: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
