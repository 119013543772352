import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useFetchExam = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('getDetailsAssessment', { id: id })
    return httpClient[method](route)
  })
  return {
    dataExam: data,
    loadingExam: loading,
    errorExam: error,
    makeRequestExam: makeRequest,
  }
}
